<template>
	<div class="investor-card w-100 m-3 fade-in">
		<div class="title">
			<span class="ido-c-title ido-c-text">{{ (props?.title || "") + " Investors" }}</span>
		</div>
		<b-row class="px-3 pb-3">
			<b-col cols="12" md="4" v-for="item in props.items" :key="item.title">
				<div class="investor-item">
					<div class="d-flex justify-content-start align-items-center info">
						<b-avatar :src="toUrlBase(item.logo)" :alt="item.title"></b-avatar>
						<div class="brandname">{{ item.title }}</div>
					</div>
					<div class="socials">
						<b-icon
							v-b-tooltip.hover
							title="Website"
							icon="link45deg"
							class="s-item border rounded rounded-circle"
							v-if="item.website"
							@click="share.openLink(item.website)"
						></b-icon>
						<template v-for="(it, sKey) in item.socials" :key="sKey">
							<b-icon
								v-if="it && icons[sKey] && icons[sKey].icon"
								v-b-tooltip.hover
								:title="sKey"
								class="s-item border rounded rounded-circle"
								@click="share.openLink(it)"
								:icon="icons[sKey].icon"
							></b-icon>
							<b-avatar
								v-else
								:alt="sKey"
								:text="`${sKey}`.substring(0, 1)"
								class="s-item av border rounded rounded-circle"
								@click="share.openLink(it)"
								v-b-tooltip.hover
								:title="sKey"
							></b-avatar>
						</template>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script setup>
import share from "@/utils/share";
import { defineProps } from "vue";
import { keyBy } from "lodash";

const props = defineProps(["items", "title"]);

const icons = keyBy(share.socialsList, "value");
</script>

<style lang="scss">
.investor-card {
	transition: var(--trans-out);
	box-sizing: border-box;
	border: 2px solid rgba(255, 255, 255, 0.2);
	filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05));
	border-radius: 1.5rem;
	&::before {
		background-color: #000 !important;
	}
	.title {
		padding: 16px 4px;
		margin: 0 16px;
		border-bottom: 1px solid var(--border-color);
		&:before {
			background-color: #000;
			opacity: 0.5;
		}
	}
	.row {
		.investor-item {
			margin-top: 1rem;
			border: 1px solid var(--border-color);
			border-radius: 1rem;
			padding: 1rem;
			text-align: center;
			display: flex;
			gap: 0.5rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-family: var(--font-family);
			.info {
				gap: 1rem;
				.brandname {
					font-family: var(--font-family);
					font-size: 1rem;
					font-weight: 500;
				}
			}
			.socials {
				display: flex;
				gap: 0.5rem;
				.s-item {
					cursor: pointer;
					width: 1.5rem;
					height: 1.5rem;
					padding: 0.25rem;
					&.av {
						background-color: transparent;
					}
				}
			}
		}
	}
}
</style>
