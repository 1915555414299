import Api, { authHeader } from "./base.api";

const END_POINT = "favorite";

export default {
	favorite(id, data) {
		const headers = authHeader.get();
		return Api.put(END_POINT + "/" + id, data, { headers: headers || {} });
	},
};
