<template>
	<div class="shadow px-3 py-3">
		<b-row class="text-left">
			<b-col cols="12" md="12">
				<b-form-group label="Name" label-for="txt-name">
					<b-form-input type="text" id="txt-name" placeholder="Name" v-model="form.name" trim></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="12">
				<b-form-group label="Order No" label-for="txt-order_no">
					<b-form-input
						id="txt-order_no"
						placeholder="Order No"
						type="number"
						v-model="form.order_no"
						trim
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="12">
				<b-form-group label="Description" label-for="txt-description">
					<b-form-textarea
						id="txt-description"
						placeholder="Description"
						v-model="form.description"
						rows="3"
						max-rows="6"
						trim
					></b-form-textarea>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="12" v-if="route.name === 'chains'">
				<b-avatar v-if="form.logo" :src="toUrlBase(form.logo)" />
				<b-form-group label="Logo" label-for="txt-logo-cat" class="ido-file">
					<b-form-file
						@change="onLogoUpload"
						id="txt-logo-cat"
						name="file"
						placeholder="Drop Logo"
						accept="image/png, image/gif, image/jpeg"
						trim
					></b-form-file>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="text-right">
			<b-col>
				<b-btn
					@click="onSave"
					variant="primary"
					class="px-3 btn-ido-primary btn-white w-100"
					:disabled="state.busy"
				>
					<b-icon icon="circle-fill" animation="throb" v-if="state.busy"></b-icon>
					<b-icon-save v-else /> Save
				</b-btn>
			</b-col>
		</b-row>
	</div>
</template>

<script setup>
import { onUpdated, reactive, defineProps, defineEmits, onBeforeUpdate, ref } from "vue";
import { useRoute } from "vue-router";
import { useToast, useUtils } from "@/uses";
import utils from "@/utils";
import { categoryApi, mediaApi } from "@/api";

const utilsNav = useUtils();

const route = useRoute();
const props = defineProps(["item"]);
const form = reactive({
	key: props.item?.key,
	name: props.item?.name,
	description: props.item?.description,
	cat: `${route.meta.key}`.toLocaleLowerCase(),
	order_no: 0,
	logo: "",
});
const state = reactive({ busy: false, id: null });
const logoFile = ref(null);
const $emit = defineEmits(["onSuccess"]);

const toast = useToast();

async function onLogoUpload($event) {
	$event.preventDefault();
	logoFile.value = $event.target.files[0];
}

async function onSave() {
	if (!form.name) {
		toast.warn("Data cannot be empty. Please check again.");
		return;
	}
	try {
		state.busy = true;
		form.key = utils.toSlug(form.name);

		if (logoFile.value) {
			try {
				const { data } = await mediaApi.upload(logoFile.value, { f: "chains" });
				if (data) {
					form.logo = data;
					logoFile.value = null;
				}
			} catch ({ message }) {
				toast.warn(message);
			}
		}

		if (state.id) {
			await categoryApi.edit(state.id, form);
		} else {
			await categoryApi.add(form);
		}
		state.id = null;
		$emit("onSuccess");
	} catch ({ message }) {
		toast.warn(message);
	} finally {
		state.busy = false;
	}
}

onBeforeUpdate(utilsNav.updateBreadcrumb);

onUpdated(() => {
	// if (props.item) {
	form.key = props.item?.key;
	form.name = props.item?.name;
	form.order_no = props.item?.order_no;
	form.description = props.item?.description;
	form.cat = `${route.meta.key}`.toLocaleLowerCase();
	state.id = props.item?._id;
	// }
});
</script>
