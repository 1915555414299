import { defineStore } from "pinia";

export default defineStore("sysconfig", {
	state: () => ({
		title: `IDO Calendar - Calendar of all projects IDO, ICO &amp; IEO...`,
	}),
	getters: {},
	actions: {
		onUpdateTitle(title) {
			if (!title) {
				title = this.title;
			} else {
				title = `${title} | IDO Calendar`;
			}
			document.title = title;
		},
	},
});
