<template>
	<div class="cat-wrap pt-1 px-2">
		<b-row class="mb-3">
			<b-col>
				<b-input-group class="m-search">
					<b-form-input
						v-model="state.s"
						class="i-search"
						placeholder="Search..."
						type="search"
						v-on:keyup.enter="fetchData"
					></b-form-input>
				</b-input-group>
			</b-col>
			<b-col>
				<div class="d-flex flex-row-reverse">
					<b-button @click="(e) => goEdit(e, null)" variant="primary" class="px-3 btn-ido-primary outline">
						<b-icon-plus /> Add New
					</b-button>
				</div>
			</b-col>
		</b-row>
		<b-table
			:items="state.items"
			:fields="state.fields"
			:busy="state.busy"
			class="ido-table"
			id="tb-cat"
			thead-class="tb-thead"
			tbody-class="tb-tbody"
			show-empty
			responsive
			striped
			hover
		>
			<template #empty>
				<tb-empty />
			</template>

			<template #table-busy>
				<tb-loading />
			</template>
			<template #cell(name)="{ item }">
				<div class="d-flex justify-content-start align-items-center">
					<b-img
						v-if="item.logo && route.name === 'chains'"
						style="width: 1.5rem; height: 1.5rem"
						:src="toUrlBase(item.logo)"
						class="mr-1"
					/>
					<div>{{ item.name }}</div>
				</div>
			</template>
			<template #cell(no)="{ index }">
				{{ index + 1 + (state.page - 1) * state.pageSize }}
			</template>
			<template #cell(actions)="{ item }">
				<div class="tb-action">
					<b-icon-pencil-square variant="light" class="a-btn" @click="goEdit($event, item)" />
					<span>|</span>
					<b-icon-trash class="a-btn" variant="danger" :id="`row-${item.key}`" />
					<b-popover :target="`row-${item.key}`" title="Confirm" triggers="focus">
						<div class="text-center">
							<div>Are you sure you want to delete this item?</div>
							<div class="d-flex mt-2 justify-content-end">
								<b-btn variant="danger" size="sm" class="px-2">Cancel</b-btn>
								<b-btn @click="onDelete($event, item)" variant="info" size="sm" class="px-4 ml-2"
									>OK</b-btn
								>
							</div>
						</div>
					</b-popover>
				</div>
			</template>
		</b-table>
		<b-row class="align-items-center page-info" v-if="state.total > 0">
			<b-col class="text-left py-2">
				<div class="px-3">Showing {{ pageFrom }} - {{ pageTo }} of {{ state.total }}</div>
			</b-col>
			<b-col class="align-items-center align-self-center">
				<div class="d-flex justify-content-end px-3" v-if="state.total > state.pageSize">
					<b-pagination
						right
						first-number
						last-number
						v-model="state.page"
						:total-rows="state.total"
						:per-page="state.pageSize"
						@change="onChangePage"
						class="my-0 tb-page"
						size="sm"
						pills
						variant="primary"
					></b-pagination>
				</div>
			</b-col>
		</b-row>

		<b-sidebar v-model="state.show" no-close-on-backdrop right variant="dark" backdrop shadow id="sb-add">
			<template #header="{ hide }">
				<div class="w-100 d-flex justify-content-between align-items-center align-self-center">
					<div class="text-capitalize">{{ route.meta?.title }}</div>
					<b-icon-x @click="hide" class="m-action" />
				</div>
			</template>
			<template #default>
				<CatAdd @onSuccess="onAdd" :item="state.item" />
			</template>
		</b-sidebar>
	</div>
</template>
<script setup>
import { categoryApi, toUrlBase } from "@/api";
import { computed, onBeforeUpdate, onMounted, onUpdated, reactive } from "vue";
import { useRoute } from "vue-router";
import CatAdd from "./CatAdd.vue";
import { useToast, useUtils } from "@/uses";
const utils = useUtils();

const toast = useToast();

const route = useRoute();

const state = reactive({
	show: false,
	items: [],
	busy: false,
	page: 1,
	pageSize: 10,
	s: "",
	oldPage: route.meta.key,
	fields: [],
	total: 0,
	item: null,
});

const pageFrom = computed(() => state.pageSize * (state.page - 1) + 1);
const pageTo = computed(() => {
	let _pageTo = state.pageSize * state.page;
	if (_pageTo > state.total) _pageTo = state.total;
	return _pageTo;
});

async function fetchData() {
	state.busy = true;
	const params = {
		page: state.page,
		pageSize: state.pageSize,
	};
	if (state.s) {
		params.s = state.s;
	}
	const { data } = await categoryApi.search(params, {
		["x-search"]: `${route.meta.key}`.toLocaleLowerCase(),
	});
	state.items = data.data;
	state.total = data.total;
	state.busy = false;
}

async function onDelete($event, item) {
	try {
		$event.preventDefault();
		const { data } = await categoryApi.delete(item._id);
		if (data) {
			toast.success();
		}
		await fetchData();
	} catch ({ message }) {
		toast.error(message);
	}
}

function onChangePage(page) {
	state.page = page;
	fetchData();
}
function onAdd() {
	state.show = false;
	fetchData();
}

function goEdit($event, item) {
	state.item = item;
	state.show = true;
}

onBeforeUpdate(() => {
	state.fields = [
		{ key: "no", class: "text-left", thClass: "tb-th-text text-center", tdClass: "tb-td-text text-center" },
		{ key: "name", class: "text-left", thClass: "tb-th-text", tdClass: "tb-td-text" },
		{
			key: "description",
			label: "Description",
			class: "text-left",
			thClass: "tb-th-text",
			tdClass: "tb-td-text",
		},
	];
	if (route.name === "categories") {
		state.fields.push({
			key: "order_no",
			label: "Order",
			class: "tb-td-text text-center",
			thClass: "tb-th-text text-center",
		});
	}
	state.fields.push({ key: "actions", label: "Actions", class: "text-right", thClass: "tb-th-text" });
	utils.updateBreadcrumb();
});

onMounted(() => {
	fetchData();
});

onUpdated(() => {
	if (state.oldPage != route.meta.key) {
		state.oldPage = route.meta.key;
		fetchData();
	}
});
</script>

<style lang="scss" scoped>
.cat-wrap {
	.m-search {
		background-color: transparent;
		.i-search {
			border-radius: 0.5rem;
			background-color: var(--bg-box);
			color: var(--text-color-primary);
			border-color: var(--border-color);
			&:focus {
				border-color: var(--text-color-primary);
				box-shadow: none;
			}
		}
	}
	#tb-cat {
		.tb-action {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 0.25rem;
			.a-btn {
				cursor: pointer;
			}
		}
	}
}
</style>
