<template>
	<div class="allocation-wrap w-100 m-3 fade-in">
		<b-row>
			<b-col>
				<div class="title mx-3 py-3 px-1 ido-c-title ido-c-text">
					<span class="ido-c-title ido-c-text">Allocations</span>
					<span v-if="props.title">{{ props.title }}</span>
				</div>
				<div class="mt-3 pt-3" v-if="state.busy">
					<tb-loading />
				</div>
				<div class="mt-3 pb-3" v-else>
					<!-- <li v-for="{ title, percentage } in items" :key="title">{{ title }}: {{ percentage }}%</li> -->
					<b-row v-if="item && item?.tokenomics?.length > 0">
						<b-col cols="12" lg="5">
							<VueApexCharts type="donut" :options="item?.chartOptions" :series="item?.series" />
						</b-col>
						<b-col cols="12" lg="7">
							<b-table
								:items="item.tokenomics"
								class="ido-table px-3"
								thead-class="tb-thead"
								tbody-class="tb-tbody"
								responsive
								:fields="fields"
							>
								<template #cell(percentage)="{ item: { percentage } }">
									{{ `${percentage}`.includes("%") ? percentage : `${percentage}%` }}
								</template>
							</b-table>
						</b-col>
					</b-row>
					<tb-empty v-else />
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script setup>
import { computed, reactive, defineProps } from "vue";
import VueApexCharts from "vue3-apexcharts";
const props = defineProps(["item", "title"]);
const state = reactive({ busy: true });
setTimeout(() => (state.busy = false), 1000);

const fields = [
	{
		key: "title",
		label: "Title",
		class: "text-left",
		thClass: "tb-th-text",
		tdClass: "tb-td-text",
	},
	{
		key: "percentage",
		label: "Percentage",
		class: "text-right",
		thClass: "tb-th-text",
		tdClass: "tb-td-text",
	},
	{
		key: "total",
		label: "Total",
		class: "text-right",
		thClass: "tb-th-text",
		tdClass: "tb-td-text",
	},
	{
		key: "description",
		label: "Description",
		class: "text-left",
		thClass: "tb-th-text",
		tdClass: "tb-td-text",
	},
];

const _chartOptions = {
	chart: {
		type: "donut",
	},
	tooltip: {
		fontFamily: "DM Sans",
	},
	dataLabels: {
		formatter: function (val) {
			return val + "%";
		},
		style: {
			fontFamily: "DM Sans",
		},
	},
	legend: {
		position: "bottom",
		fontFamily: "DM Sans",
		labels: {
			colors: "#fff",
			useSeriesColors: false,
		},
	},
	responsive: [
		{
			breakpoint: 240,
			options: {
				chart: {
					width: 100,
				},
				legend: {
					position: "bottom",
				},
			},
		},
	],
};

const item = computed(() => {
	if (props?.item?.tokenomics) {
		const tokenomics = props?.item?.tokenomics;
		const series = props?.item?.tokenomics.map(({ percentage }) => percentage);
		const chartOptions = _chartOptions;
		chartOptions.labels = props?.item?.tokenomics.map(({ title }) => title);
		return { series, chartOptions, tokenomics };
	}
	return null;
});
</script>

<style lang="scss" scoped>
.allocation-wrap {
	transition: var(--trans-out);
	box-sizing: border-box;
	min-height: 300px;
	border: 2px solid rgba(255, 255, 255, 0.2);
	filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05));
	border-radius: 1.5rem;
	.title {
		transition: var(--trans-out);
		font-family: var(--font-family);
		font-style: normal;
		font-weight: 700;
		line-height: 23px;
		display: flex;
		align-items: flex-end;
		color: var(--text-color-primary);
		border-bottom: 1px solid var(--border-color);
	}
}
</style>
