import Api from "./with-auth.api";

const END_POINT = "news";

export default {
	search(params, headers) {
		return Api.get(END_POINT, { params, headers: headers || {} });
	},
	add(data) {
		return Api.post(END_POINT, data);
	},
	edit(id, data) {
		return Api.put(`${END_POINT}/${id}`, data);
	},
	byId(id) {
		return Api.get(`${END_POINT}/${id}`);
	},
	delete(id) {
		return Api.delete(`${END_POINT}/${id}`);
	},
};
