import { defineStore } from "pinia";
import { includes, isEmpty } from "lodash";
import useAuth from "./auth";
import useFilter from "./pfilter";
import { publicApi, favoriteApi } from "@/api";
import { useSys } from ".";

const MENU_ACTIVE = "watch/menu-active";

export default defineStore("watch-project", {
	state: () => {
		const active = sessionStorage.getItem(MENU_ACTIVE) || "active";
		return {
			busy: false,
			page: 1,
			pageSize: 50,
			xType: active,
			items: [],
			total: 0,
			pageshow: false,
			pageFrom: 0,
			pageTo: 0,
		};
	},
	getters: {},
	actions: {
		onChangePage(page) {
			this.page = page;
			this.fetchData(this.xType);
		},
		onSetType(xType) {
			this.xType = xType;
			sessionStorage.setItem(MENU_ACTIVE, xType);
		},
		async projectUpdate(id, params, slug) {
			const auth = useAuth();
			if (!auth.isLogin) {
				auth.onToggle(true);
				return false;
			}
			await favoriteApi.favorite(id, params);

			if (slug) {
				this.fetchBySlug(slug);
			}

			return true;
		},
		async onWatchProject({ _id, favorite, slug }) {
			if (favorite === undefined) {
				favorite = false;
			}
			favorite = !favorite;
			if (await this.projectUpdate(_id, { field: "favorite", value: favorite }, slug))
				this.items = this.items.map((item) => {
					if (item._id == _id) {
						item.favorite = favorite;
					}
					return item;
				});
		},
		async onLikeDislike(_id, value, slug) {
			await this.projectUpdate(_id, { field: "reaction", value }, slug);
		},
		async onRate(id, value, slug) {
			await this.projectUpdate(id, { field: "rate", value }, slug);
		},
		async fetchData(xType, query = {}) {
			const sys = useSys();
			sys.onUpdateTitle();
			this.busy = true;
			this.xType = xType;
			sessionStorage.setItem(MENU_ACTIVE, xType);
			const params = {
				page: this.page,
				pageSize: this.pageSize,
				...query,
			};
			if (query.page) {
				this.page = query.page;
			}
			const { total, data } = await publicApi.watchProject(params, { "x-type": xType });
			this.items = data.map((item) => {
				item.favorite = true;
				return item;
			});

			this.total = total;
			this.busy = false;
			this.pageShow = Number(total) > Number(this.pageSize);
			this.pageTo = this.pageSize * this.page;
			if (this.pageTo > total) this.pageTo = total;
			this.pageFrom = this.pageSize * (this.page - 1) + 1;
		},
		async fetchBySlug(slug) {
			try {
				this.item = await publicApi.projectBySlug(slug);
				const sys = useSys();
				sys.onUpdateTitle(this.item?.name);
			} catch (error) {
				console.log("err ===> ", error);
			}
		},
		async goDetail(item) {
			this.item = item;
		},
		async onFilter(event) {
			if (event) event.preventDefault();
			const uFilter = useFilter();
			const filter = uFilter.wParams;
			const params = {};
			for (let k in filter) {
				if (includes(["category", "s", "stage", "chain", "startAt", "endAt"], k) && !isEmpty(filter[k])) {
					params[k] = filter[k];
				}
			}
			this.fetchData(this.xType, params);
		},
	},
});
