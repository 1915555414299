<template>
	<ido-content title="Project">
		<template #menu-item>
			<div
				class="menu-left-item fade-in"
				v-for="n in navProjects"
				:key="n.key"
				@click="onActive(n.key)"
				:class="{ active: mActive === n.key, collapse: collapse.active }"
			>
				<b-icon :icon="n.icon" class="icon" v-if="collapse.active" v-b-tooltip.hover :title="n.title" />
				<b-icon :icon="n.icon" class="icon" v-else />
				<span v-if="!collapse.active">{{ n.title }} </span>
			</div>
		</template>
		<template #body>
			<!-- Body -->
			<div class="project-wrap page-content px-3 mb-3">
				<b-row class="px-2">
					<!-- left -->
					<b-col cols="12" lg="6" class="d-info p-2" v-if="project">
						<div class="p-4 sale-info fade-in">
							<div class="d-flex w-100">
								<div class="pl-2"><b-img class="d-logo" :src="toUrlBase(project?.logo)" /></div>
								<div class="info-detail w-100 pl-3">
									<div class="d-flex justify-content-start align-items-center">
										<div class="name">{{ project.name }}</div>
										<div class="symbol ml-3" v-if="project.symbol">{{ project?.symbol }}</div>
										<template v-if="project?.favorite !== null">
											<b-icon-star-fill
												v-if="project?.favorite"
												class="ml-3 m-action ifavorite active"
												@click="store.onWatchProject(project)"
												v-b-tooltip.hover
												color="ffc107"
												title="Remove to watchlist"
											/>
											<b-icon-star
												v-else
												class="ml-3 m-action ifavorite inactive"
												@click="store.onWatchProject(project)"
												v-b-tooltip.hover
												title="Add to watchlist"
												color="#e4e7ee"
											/>
										</template>
									</div>
									<div class="description" v-html="project.about"></div>
									<div class="c-row" v-if="project?.category?.name">
										<div class="title">Category:</div>
										<div class="value">{{ project?.category?.name }}</div>
									</div>
									<div class="c-row" v-if="project?.chain?.name">
										<div class="title">Chain:</div>
										<div class="value">{{ project?.chain?.name }}</div>
									</div>
									<div class="d-flex m-info">
										<div v-if="project?.stage?.name" size="sm" class="stage">
											{{ project?.stage?.name }}
										</div>
										<div class="ido-rating">
											<b-form-rating
												id="rating-sm"
												v-model="project.rate"
												variant="warning"
												@change="(rate) => store.onRate(project?._id, rate, route.params?.slug)"
												size="sm"
												class="bg-transparent border-0 rating-action"
												inline
											></b-form-rating>
										</div>
									</div>
									<div class="d-flex flex">
										<div v-if="project" class="w-100">
											<div class="my-2 sTitle">Social Media</div>
											<b-row>
												<b-col class="d-flex justify-content-start align-items-center m-0 p-0">
													<div class="item-socials pl-3">
														<b-icon
															class="i-social"
															icon="globe"
															font-scale="1.5"
															v-if="project?.website"
															v-b-tooltip.hover
															title="Website"
															@click="share.openLink(project?.website, '_blank')"
															style="color: #fff"
														/>
														<template
															v-for="sItem in socials.slice(
																0,
																SMAX > 1 ? state.SMAX : state.SMAX + 1
															)"
															:key="sItem.key"
														>
															<div @click="share.openLink(sItem.link, '_blank')">
																<b-avatar
																	v-if="sItem.img"
																	class="i-social"
																	:src="sItem.img"
																	size="1.5rem"
																	v-b-tooltip.hover
																	:title="sItem.text"
																	:data="sItem.link"
																	:style="`background: ${sItem.color}`"
																/>
																<b-icon
																	v-else-if="sItem.icon"
																	class="i-social"
																	:icon="sItem.icon"
																	font-scale="1.5"
																	v-b-tooltip.hover
																	:title="sItem.text"
																	:style="`color: ${sItem.color}`"
																/>
																<b-avatar
																	v-else
																	class="i-social"
																	:text="sItem.text"
																	size="1.5rem"
																	v-b-tooltip.hover
																	:title="sItem.text"
																	:style="`background: ${sItem.color}`"
																/>
															</div>
														</template>
														<div @click="onSocialMore($bvModal)">
															<b-avatar
																v-if="SMAX > 1"
																class="i-social m-action m-more"
																:text="`+${SMAX}`"
																size="1.5rem"
															/>
														</div>
													</div>
												</b-col>
												<b-col>
													<div class="f-like">
														<div
															class="m-action f-color"
															@click="
																store.onLikeDislike(
																	project?._id,
																	'like',
																	route.params?.slug
																)
															"
														>
															<b-icon icon="hand-thumbs-up" class="i-color mr-1" />
															{{ project?.likes || 0 }} Likes
														</div>
														<div
															class="f-color m-action"
															@click="
																store.onLikeDislike(
																	project?._id,
																	'dislike',
																	route.params?.slug
																)
															"
														>
															<b-icon icon="hand-thumbs-down" class="i-color" />
															<span class="ml-2">{{ project?.dislikes || 0 }}</span>
															Dislikes
														</div>
													</div>
												</b-col>
											</b-row>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-col>
					<!-- right -->
					<b-col cols="12" lg="6" class="p-2">
						<div class="row m-1 p-4 sale-info">
							<div class="w-100">
								<b-row>
									<b-col cols="12">
										<span class="ido-c-text ido-c-title">
											<span>{{ project?.name }}</span>
											<span v-if="project?.symbol" class="ml-1">({{ project?.symbol }})</span>
											Token Sale Detail
										</span>
									</b-col>
								</b-row>
								<div class="border-top pt-2 w-100 mt-2 line"></div>
								<b-row class="mx-0">
									<b-col class="token-sales px-1">
										<b-row class="mx-0 ts-wrap">
											<b-col cols="6" lg="4" md="4" class="ml-0 text-left px-1">
												<div class="s-title fade-in text-center">Total supply</div>
												<div class="s-value fade-in text-center">
													{{ project?.supply || "-" }}
												</div>
											</b-col>
											<b-col cols="6" lg="4" md="4" class="ml-0 text-left px-1">
												<div class="s-title text-center">Hard Cap</div>
												<div class="s-value text-center">
													{{ project?.hardtcap || "-" }}
												</div>
											</b-col>
											<b-col cols="6" lg="4" md="4" class="ml-0 text-left px-1">
												<div class="s-title fade-in text-center">Soft Cap</div>
												<div class="s-value fade-in text-center">
													{{ project?.softcap || "-" }}
												</div>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<b-row class="px-0">
									<b-col
										cols="6"
										lg="4"
										md="4"
										v-if="!(project?.tokenSales && project?.tokenSales.length > 0)"
									>
										<div class="s-title fade-in">Ticker Symbol</div>
										<div class="s-value fade-in">{{ project?.symbol || project?.name }}</div>
									</b-col>
									<template v-if="!(project?.tokenSales && project?.tokenSales.length > 0)">
										<b-col cols="6" lg="4" md="4" v-for="(item, index) in tokenOption" :key="index">
											<div class="sTitle fade-in">{{ item.title }}</div>
											<template v-if="item.type === 'url'">
												<m-link
													v-if="item.key == 'launchpad'"
													:item="project?.saleInfo?.launchpad"
													mTitle="lp_title"
													mLink="lp_link"
													mLogo="lp_logo"
													mClass="s-value link"
												></m-link>
												<div
													v-else
													class="s-value link m-action fade-in"
													@click="share.openLink(project?.saleInfo[item.key], '_blank')"
												>
													{{ item.text }}
												</div>
											</template>

											<div class="s-value" v-else-if="item.key == 'main_supply'">
												{{ project?.supply }}
											</div>
											<div class="s-value" v-else>{{ project?.saleInfo[item.key] }}</div>
										</b-col>
									</template>
									<b-col cols="12" v-if="project?.tokenSales && project?.tokenSales.length > 0">
										<b-row
											class="mx-0"
											v-for="(ts, tsInx) in project?.tokenSales.slice(
												0,
												state.showMore * state.STEP
											)"
											:key="`ts-x-${tsInx}`"
										>
											<b-col class="token-sales px-1">
												<b-row class="title mx-0 fade-in">{{ toTitle(ts) }}</b-row>
												<b-row class="mx-0 ts-wrap">
													<b-col cols="6" lg="3" md="3" class="ml-0 text-left px-1">
														<div class="s-title fade-in">Sale Price</div>
														<div class="s-value fade-in">{{ ts?.tPrice }}</div>
													</b-col>
													<b-col cols="6" lg="3" md="3" class="ml-0 text-left px-1">
														<div class="s-title fade-in">Raise</div>
														<div class="s-value fade-in">{{ ts?.tRaise }}</div>
													</b-col>
													<b-col cols="6" lg="3" md="3" class="ml-0 text-center px-1">
														<div class="s-title mb-1">Launchpad</div>
														<m-link
															:item="ts"
															mTitle="tlpName"
															mLink="tlpLink"
															mLogo="tlpLogo"
															mClass="s-value link"
														></m-link>
														<!-- <div
														class="s-value link m-cursor"
														@click="share.openLink(ts?.tlpLink)"
													>
														{{ ts?.tlpName }}
													</div> -->
													</b-col>
													<b-col cols="6" lg="3" md="3" class="ml-0 text-left px-1">
														<div class="s-title fade-in">Tokens For Sale</div>
														<div class="s-value fade-in">{{ ts?.tSupply }}</div>
													</b-col>
												</b-row>
											</b-col>
										</b-row>
										<b-row
											v-if="project?.tokenSales.length > state.showMore * state.STEP"
											class="mx-0 m-cursor text-right"
											@click="state.showMore++"
										>
											<div class="w-100 text-center">Show more</div>
										</b-row>
									</b-col>
									<b-col
										cols="12"
										v-if="false && project?.investors && project?.investors.length > 0"
									>
										<div class="sTitle my-1 m-cursor p-font">
											Investors
											<b-icon-info-circle
												v-if="project?.investors.length > 0"
												@click="onInvestorShow($bvModal, project)"
											></b-icon-info-circle>
										</div>
										<b-row>
											<template v-for="inv in project?.investors" :key="`inv-${inv.title}`">
												<b-col
													cols="6"
													md="6"
													class="mb-2 m-cursor p-font brandname"
													@click="share.openLink(inv.website)"
												>
													<b-avatar
														v-if="inv.logo"
														:src="toUrlBase(inv.logo)"
														class="s-icon mr-1"
													/>
													<b-avatar v-else text="#" class="s-icon mr-1" />
													{{ inv.title }}
												</b-col>
											</template>
										</b-row>
									</b-col>
								</b-row>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<AllocationChart :item="project" />
				</b-row>
				<b-row v-if="project?.investors.length > 0">
					<InvestorCard :title="state?.item?.name" :items="project?.investors" />
				</b-row>
				<InvestorModal :title="state?.item?.name" :items="state?.item?.investors" />
				<SocialsModal :title="project?.name" :items="socials" />
			</div>
		</template>
	</ido-content>
</template>

<script setup>
import { computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import InvestorModal from "./InvestorModal.vue";
import InvestorCard from "./InvestorCard.vue";
import SocialsModal from "./SocialsModal.vue";
// import { storeToRefs } from "pinia";
import AllocationChart from "./Allocations.vue";
import { useProject, useWatch, useCollapse } from "@/uses";
import utils, { navProjects } from "@/utils";
import share from "@/utils/share";
import moment from "moment";
import { keyBy } from "lodash";

const collapse = useCollapse();

function toTitle({ tStage, tStartAt, tEndAt }) {
	if (typeof tStartAt == "string") {
		tStartAt = utils.toStartAt(tStartAt, tEndAt);
	}
	if (tStartAt && tEndAt) {
		const now = moment();

		let status = "";
		const s = moment(tStartAt);
		const e = moment(tEndAt);
		if (now > e) {
			status = " Ended";
		}
		if (now < e && now > s) {
			status = " is Active";
		}

		if (s > now) status = " Coming Soon";

		if (tStage) return `${tStage}${status}: ${tStartAt} - ${tEndAt}`;

		return "";
	}

	if (tStage) return `${tStage} Coming Soon: TBA`;

	return "";
}

const tokenOption = [
	// { key: "tType", title: "Token Type", md: 4, type: "text" },
	{ key: "main_supply", title: "Supply", md: 4, type: "text" },
	{ key: "supply", title: "Tokens for Sale", md: 4, type: "text" },
	{ key: "price", title: "Token Sale Price", md: 4, type: "text" },
	{ key: "link", title: "Token Sale Page", md: 6, type: "url", text: "Click here" },
	{ key: "launchpad", title: "Launchpad", md: 6, type: "url", text: "Click here" },
	// { key: "investor", title: "Investor", md: 6, type: "url", text: "Click here" },
];

const route = useRoute();
const router = useRouter();
const store = useProject();
const storeWatch = useWatch();
const project = computed(() => store.item);
const socials = computed(() => {
	const stmp = [];
	if (!project?.value) return stmp;
	const { socialLink } = project?.value;
	const { socialsList } = utils.share;
	const tmp = keyBy(socialsList, "value");
	for (const key in socialLink) {
		const link = socialLink[key];
		if (link && tmp && tmp[key] && tmp[key].color) {
			stmp.push({ ...tmp[key], key, link });
		}
	}
	return stmp;
});
const state = reactive({ item: null, showMore: 1, STEP: 3, SMAX: 2 });

const SMAX = computed(() => {
	return socials.value.length - state.SMAX;
});

function onInvestorShow($bvModal, it) {
	state.item = it;
	$bvModal.show("modal-socials");
}

function onSocialMore($bvModal) {
	$bvModal.show("modal-socials2");
}

const mActive = computed(() => {
	if (route.name === "w-project-detail") {
		return storeWatch.xType;
	}

	if (route.name === "project-detail") {
		return store.xType;
	}

	return "active";
});

function onActive(name) {
	if (route.name === "w-project-detail") {
		router.push({ name: "watch" });
		storeWatch.fetchData(name, { page: 1 });
	} else if (route.name === "project-detail") {
		router.push({ name: "home" });
		store.fetchData(name, { page: 1 });
	}
	// useUtil.updateProjectActive(name);
}

onMounted(() => {
	if (route.params.slug) store.fetchBySlug(route.params.slug);
});
</script>

<style lang="scss" scoped>
.project-wrap {
	font-family: var(--font-family);
	height: calc(100vh - var(--nav-height) - 2rem);
	overflow-y: auto;
	transition: var(--trans-out);
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		background-color: #5e274e;
		width: 0.25rem;
		border-radius: 0.125rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-white);
		border-radius: 0.125rem;
	}

	.page-header {
		display: grid;
		grid-template-columns: auto auto;
		align-items: center;
		min-height: 3.25rem;
		justify-content: space-between;
		.title {
			font-size: 1.5rem;
			font-weight: 600;
			font-family: var(--font-family);
		}
		.total-desc {
			font-size: 0.75rem;
			line-height: 1.2;
			font-weight: 500;
			color: #adafc2;
			font-family: var(--font-family);
		}
	}
	.d-info {
		.sTitle {
			margin: 0;
			font-weight: 500;
			font-size: 1rem;
			line-height: 1.3rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-family: var(--font-family);
		}
		.d-logo {
			width: 144px;
			border-radius: 4px;
			@media only screen and (max-width: 768px) {
				width: 64px;
			}
		}
		.info-detail {
			font-family: var(--font-family);
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: flex-start;
			gap: 8px;
			.m-info {
				gap: 1rem;
			}
			.name {
				font-family: var(--font-family);
				font-weight: 700;
				line-height: 1.2;
				font-size: 1.25rem;
				margin: 0;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				font-style: normal;
				font-weight: 700;
				font-size: 1.125rem;
				line-height: 1.438rem;
				background: linear-gradient(101.61deg, #ffa9c8 -14.72%, #c927ff 66.97%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				color: var(--text-color-primary);
			}
			.symbol {
				font-family: var(--font-family);
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.438rem;
				color: var(--color-gray-normal);
				text-transform: uppercase;
			}
			.c-row {
				display: flex;
				font-family: var(--font-family);
				align-items: center;
				justify-content: flex-start;
				gap: 0.5rem;
				.title {
					margin: 0;
					font-weight: 400;
					font-size: 0.85rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					color: #ba8dae;
				}
				.value {
					min-width: 0;
					margin: 0;
					font-weight: 500;
					font-size: 1rem;
					line-height: 1.3rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
			.description {
				font-size: 0.875rem !important;
				font-family: var(--font-family);
				font-style: normal;
				font-weight: 400;
				line-height: 1.25rem;
				min-height: 3rem;
				text-align: justify;
				color: #ffffff;
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-size: 1rem !important;
					font-family: var(--font-family);
				}
			}
			.f-like {
				margin: 0;
				padding: 0;
				display: flex;
				gap: 0.5rem;
				justify-content: flex-end;
				font-family: var(--font-family);
				.i-color {
					color: #0095ff;
				}
				.f-color {
					color: #ba8dae;
					font-size: 1rem;
				}
			}
			.ifavorite {
				color: #bfbfbf;
				font-family: var(--font-family);
				&.active {
					color: #ffc107;
					&:hover {
						color: #bfbfbf;
					}
				}
				&.inactive {
					color: #bfbfbf;
					&:hover {
						color: #ffc107;
					}
				}
			}
			.ido-rating {
				border-color: transparent;
				border-radius: 0.5rem;
				position: relative;
				background-clip: padding-box;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 1px;
					background: linear-gradient(to right, #e11a80, #880ab2);
					-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					mask-composite: exclude;
					-webkit-mask-composite: destination-out;
					border-radius: 0.5rem;
					z-index: 1;
				}
				.rating-action {
					z-index: 999;
					position: relative;
					border: none;
				}
			}
			.stage {
				position: relative;
				background-clip: padding-box;
				font-family: var(--font-family);
				border-radius: 0.5rem;
				font-size: 0.75rem;
				color: var(--text-color-primary);
				font-weight: 400;
				line-height: 1rem;
				justify-content: center;
				align-items: center;
				display: flex;
				&:hover {
					background-color: transparent;
				}
				padding: 0.25rem 1rem;
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 1px;
					background: linear-gradient(to right, #e11a80, #880ab2);
					-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					mask-composite: exclude;
					-webkit-mask-composite: destination-out;
					border-radius: 0.5rem;
				}
			}
		}
		.item-socials {
			display: flex;
			gap: 0.75rem;
			font-family: var(--font-family);
			.i-social {
				cursor: pointer;
				width: 1.5rem;
				height: 1.5rem;
				&.m-more {
					background-color: transparent;
					border: 1px solid #fff;
					color: #fff;
					font-weight: 600;
					&:hover {
						background-color: var(--border-color);
						border-color: var(--border-color);
						color: var(--border-color);
						border-width: 2px;
					}
				}
			}
		}
	}

	.sale-info {
		font-family: var(--font-family);
		height: 100%;
		border: var(--border-card);
		border-radius: 1.5rem;
		transition: var(--trans-out);
		.line {
			border-color: var(--border-color) !important;
		}
		.h-title {
			font-weight: 700;
			line-height: 1.25rem;
			font-size: 1rem;
			margin: 0;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-family: var(--font-family);
		}
		.s-title {
			margin: 0;
			font-weight: 500;
			font-size: 1.25rem;
			line-height: 1.25rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-family: var(--font-family);
		}
		.s-value {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			font-family: var(--font-family);
			min-width: 0;
			margin: 0;
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 1.125rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: #ba8dae;
			&.link {
				color: #20a2ff;
				text-decoration: underline;
			}
		}

		.s-icon {
			width: 1.5rem;
			height: 1.5rem;
		}

		.brandname {
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 1.3;
		}

		.token-sales {
			transition: var(--trans-out);
			.title {
				transition: var(--trans-out);
				font-weight: 600;
				line-height: 1.125rem;
				font-size: 1rem;
				margin: 0;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				font-family: var(--font-family);
				padding: 0.5rem 0;
			}
			.s-title {
				transition: var(--trans-out);
				margin: 0;
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.125rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-family: var(--font-family);
			}
			.s-value {
				transition: var(--trans-out);
				font-family: var(--font-family);
				min-width: 0;
				margin: 0;
				font-weight: 500;
				font-size: 0.875rem;
				line-height: 1.125rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: #ba8dae;
				&.link {
					color: #20a2ff;
					text-decoration: underline;
				}
			}

			.ts-wrap {
				border-bottom: 1px dotted var(--border-color);
				padding-bottom: 0.5rem;
				margin-bottom: 0.5rem;
			}
		}
	}
}
</style>
