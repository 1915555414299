import axios from "axios";
import projectApi from "./project.api";
import categoryApi from "./category.api";
import authApi from "./auth.api";
import publicApi from "./public.api";
import mediaApi from "./media.api";
import favoriteApi from "./favorite.api";
import newsApi from "./news.api";
import researchApi from "./research.api";
import usersApi from "./users.api";
import { BASE_URL } from "./base.api";

export default {
	projects: `https://api.idocalendar.com/v1posts?filter=%7B%22limit%22%3A10%2C%22skip%22%3A0%2C%22order%22%3A%5B%22publishedAt%20DESC%22%5D%2C%22where%22%3A%7B%22and%22%3A%5B%7B%22endAt%22%3A%7B%22lte%22%3A1702005426%7D%7D%2C%7B%22endAt%22%3A%7B%22neq%22%3A0%7D%7D%5D%7D%2C%22fields%22%3A%7B%22id%22%3Atrue%2C%22name%22%3Atrue%2C%22slug%22%3Atrue%2C%22image%22%3Atrue%2C%22description%22%3Atrue%2C%22content%22%3Atrue%2C%22rate%22%3Atrue%2C%22symbol%22%3Atrue%2C%22price%22%3Atrue%2C%22fund%22%3Atrue%2C%22startAt%22%3Atrue%2C%22endAt%22%3Atrue%2C%22acceptCoin%22%3Atrue%2C%22personalCap%22%3Atrue%2C%22accessType%22%3Atrue%2C%22tokenForSale%22%3Atrue%2C%22totalSupply%22%3Atrue%2C%22typeSale%22%3Atrue%2C%22website%22%3Atrue%2C%22whitepaper%22%3Atrue%2C%22facebookLink%22%3Atrue%2C%22twitterLink%22%3Atrue%2C%22telegramLink%22%3Atrue%2C%22createdAt%22%3Atrue%2C%22updatedAt%22%3Atrue%2C%22publishedAt%22%3Atrue%7D%2C%22include%22%3A%5B%7B%22relation%22%3A%22category%22%2C%22scope%22%3A%7B%22where%22%3A%7B%22type%22%3A%22cate%22%7D%7D%7D%2C%7B%22relation%22%3A%22chain%22%2C%22scope%22%3A%7B%22where%22%3A%7B%22type%22%3A%22chain%22%7D%7D%7D%2C%7B%22relation%22%3A%22watchLists%22%2C%22scope%22%3A%7B%22where%22%3A%7B%22id%22%3Anull%7D%7D%7D%5D%7D`,
	gasInfo: "https://api.cryptorank.io/v0/global",
	category: BASE_URL + "/category",
	project: BASE_URL + "/project",
	BASE_URL,
};

export { BASE_URL, projectApi, categoryApi, authApi, publicApi, mediaApi, favoriteApi, newsApi, usersApi, researchApi };

export const Api = axios.create({
	baseURL: BASE_URL,
});

export const toUrlBase = (_img) => {
	if (!_img) return null;
	if (_img && _img.startsWith("http")) return _img;
	if (_img == "/logo.png") return _img;
	return BASE_URL + _img;
};

export const toUrlBaseSave = (_img, key) => {
	let link = toUrlBase(_img);
	let tmp = null;
	if (key) {
		tmp = `ido-img-${key}`.toLowerCase();
	}

	if (link && tmp) {
		localStorage.setItem(tmp, link);
	}

	if (!link && tmp) {
		link = localStorage.getItem(tmp);
	}

	return link;
};
