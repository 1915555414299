import { defineStore } from "pinia";
import { publicApi } from "@/api";

const dfVal = {
	stage: "",
	chain: "",
	category: "",
	tags: "",
	endAt: "",
	startAt: "",
};

function toPrams(o) {
	const p = {};
	if (o.category) p.category = o.category;
	if (o.stage) p.stage = o.stage;
	if (o.chain) p.chain = o.chain;
	if (o.startAt) p.startAt = o.startAt;
	if (o.s) p.s = o.s;
	if (o.endAt) p.endAt = o.endAt;
	if (o.tags) p.tags = o.tags;
	return p;
}

export default defineStore("pfilter", {
	state: () => ({
		home: {
			s: "",
			...dfVal,
		},
		watch: {
			s: "",
			...dfVal,
		},
		categories: [],
		stages: [],
		chains: [],
	}),
	getters: {
		wParams() {
			return toPrams(this.watch);
		},
		hParams() {
			return toPrams(this.home);
		},
	},
	actions: {
		fetchCategories: async function () {
			const rs = await publicApi.categories(
				{},
				{
					"x-search": "category-chain-stage",
				}
			);
			this.categories = rs.category || [];
			this.stages = rs.stage || [];
			this.chains = rs.chain || [];
		},
		changeValue(key, value, name) {
			if (name) this[name][key] = value;
			else {
				this[key] = value;
			}
		},
		onClear(name) {
			this[name].category = "";
			this[name].stage = "";
			this[name].chain = "";
			this[name].textSearch = "";
		},
	},
});
