import Api from "./base.api";

const END_POINT = "media/upload";

export default {
	upload: async (file, params, headers) => {
		if (!file) {
			return null;
		}
		headers = headers || {};
		// headers["Content-Type"] = "multipart/form-data";
		params = params || { f: "logo" };
		const fileLogo = params.f || "logo";
		const formData = new FormData();
		formData.append("file", file);
		formData.append("f", fileLogo);
		try {
			const { data } = await Api.post(END_POINT, formData, {
				params,
				headers,
			});
			return data;
		} catch (error) {
			return null;
		}
	},
};
