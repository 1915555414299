<template>
	<b-modal id="modal-socials" :title="(props?.title || '') + ' Investors'" hide-footer>
		<b-row>
			<b-col cols="12" md="6" v-for="item in props.items" :key="item.title">
				<div class="investor-item">
					<b-avatar :src="toUrlBase(item.logo)" :alt="item.title"></b-avatar>
					<div class="brandname">{{ item.title }}</div>
					<div class="socials">
						<b-icon
							v-b-tooltip.hover
							title="Website"
							icon="link45deg"
							class="s-item border rounded rounded-circle"
							v-if="item.website"
							@click="share.openLink(item.website)"
						></b-icon>
						<template v-for="(it, sKey) in item.socials" :key="sKey">
							<b-icon
								v-b-tooltip.hover
								:title="sKey"
								class="s-item border rounded rounded-circle"
								v-if="it && icons[sKey] && icons[sKey].icon"
								@click="share.openLink(it)"
								:icon="icons[sKey].icon"
							></b-icon>
							<b-avatar
								v-else
								:alt="sKey"
								:text="`${sKey}`.substring(0, 1)"
								class="s-item av border rounded rounded-circle"
								@click="share.openLink(it)"
								v-b-tooltip.hover
								:title="sKey"
							></b-avatar>
						</template>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>
<script setup>
import share from "@/utils/share";
import { defineProps } from "vue";
import { keyBy } from "lodash";

const props = defineProps(["items", "title"]);

const icons = keyBy(share.socialsList, "value");
</script>

<style lang="scss">
#modal-socials {
	.modal-body {
		padding: 0px 1rem 1rem 1rem;
		.row {
			.investor-item {
				margin-top: 1rem;
				border: 1px solid var(--border-color);
				border-radius: 1rem;
				padding: 1rem;
				text-align: center;
				display: flex;
				gap: 0.5rem;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-family: var(--font-family);
				.brandname {
					font-size: 0.75rem;
					font-weight: 400;
				}
				.socials {
					display: flex;
					gap: 0.5rem;
					.s-item {
						cursor: pointer;
						width: 1.5rem;
						height: 1.5rem;
						padding: 0.25rem;
						&.av {
							background-color: transparent;
						}
					}
				}
			}
		}
	}
}
</style>
