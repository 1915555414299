const TOKEN = "ido/auth/token";
const AUTH_TOKEN = "auth/token";
const NAVACTIVE = "nav/active";

export default {
	update: (token) => {
		localStorage.setItem(TOKEN, token);
	},
	get: () => {
		return localStorage.getItem(TOKEN);
	},
	remove: () => {
		localStorage.removeItem(TOKEN);
	},
};

export const localAuth = {
	save: function (token) {
		localStorage.setItem(AUTH_TOKEN, token);
	},
	del: function () {
		localStorage.removeItem(AUTH_TOKEN);
	},
	get: function () {
		return localStorage.getItem(AUTH_TOKEN);
	},
};

export const navActive = {
	save: function (active) {
		localStorage.setItem(NAVACTIVE, active);
	},
	del: function () {
		localStorage.removeItem(NAVACTIVE);
	},
	get: function () {
		return localStorage.getItem(NAVACTIVE);
	},
};
