import { createRouter, createWebHistory } from "vue-router";
import ProjectList from "@/views/project/ProjectList.vue";
import WatchProject from "@/views/watch";
import ProjectDetail from "@/views/project/ProjectDetail.vue";
import AdminLayout from "@/views/admin/AdminLayout.vue";
import CatView from "@/views/admin/cat/CatView.vue";
import ProjectForm from "@/views/admin/aproject/ProjectForm.vue";
import { useAuth } from "@/uses";
import { localAuth } from "@/utils/local";

export const P_ROUTER = [
	"access-denied",
	"project-detail",
	"w-project-detail",
	"watch",
	"ended",
	"upcoming",
	"active",
	"about-us",
	"research",
	"news",
	"news-detail",
	"research-detail",
	"home",
];

const routes = [
	{
		path: "/",
		name: "home",
		component: ProjectList,
		title: "Home",
		meta: { title: "Projects" },
	},
	// {
	// 	path: "/upcoming",
	// 	name: "upcoming",
	// 	component: ProjectList,
	// 	meta: { title: "UpComing" },
	// },
	// {
	// 	path: "/ended",
	// 	name: "ended",
	// 	meta: { title: "Ended" },
	// 	component: ProjectList,
	// },
	// {
	// 	path: "/research",
	// 	name: "research",
	// 	meta: { title: "Research" },
	// 	// component: () => import("@/views/ReSearch"),
	// 	component: () => import("@/components/ComingSoon"),
	// },
	{
		path: "/research",
		component: () => import("@/views/research/Layout"),
		children: [
			{
				path: "",
				name: "research",
				meta: { title: "Research" },
				component: () => import("@/views/research/index.vue"),
			},
			{
				path: ":slug",
				name: "research-detail",
				meta: { title: "Research" },
				component: () => import("@/views/news/NewsDetail"),
			},
		],
	},
	{
		path: "/news",
		component: () => import("@/views/news/NewsLayout"),
		children: [
			{
				path: "",
				name: "news",
				meta: { title: "News" },
				component: () => import("@/views/news/News"),
			},
			{
				path: ":slug",
				name: "news-detail",
				meta: { title: "News" },
				component: () => import("@/views/news/NewsDetail"),
			},
		],
	},
	{
		path: "/watch",
		name: "watch",
		meta: { title: "Watch" },
		component: WatchProject,
	},
	{
		path: "/h/:slug",
		name: "project-detail",
		component: ProjectDetail,
		meta: { title: "Project Detail" },
	},
	{
		path: "/w/:slug",
		name: "w-project-detail",
		component: ProjectDetail,
		meta: { title: "Project Detail" },
	},
	{
		path: "/admin",
		component: AdminLayout,
		children: [
			{ path: "stages", name: "stages", component: CatView, meta: { title: "Stage list", key: "stage" } },
			{
				path: "categories",
				name: "categories",
				component: CatView,
				meta: { title: "Category list", key: "category" },
			},
			{ path: "chains", name: "chains", component: CatView, meta: { title: "Chain list", key: "chain" } },
			{
				path: "projects",
				name: "projects",
				component: () => import("@/views/admin/aproject"),
				meta: { title: "Projects" },
			},
			{
				path: "project/add",
				name: "project-add",
				component: ProjectForm,
				meta: { parent: [{ text: "Projects", to: { name: "projects" } }], title: "Add New Project" },
			},
			{
				path: "project/edit/:id",
				name: "project-edit",
				component: ProjectForm,
				meta: { parent: [{ text: "Projects", to: { name: "projects" } }], title: "Edit Project" },
			},
			{
				path: "news",
				name: "admin-news",
				component: () => import("@/views/admin/news"),
				meta: { title: "News" },
			},
			{
				path: "news/add",
				name: "news-add",
				component: () => import("@/views/admin/news/NewsForm.vue"),
				meta: { parent: [{ text: "News", to: { name: "admin-news" } }], title: "Add New News" },
			},
			{
				path: "news/edit/:id",
				name: "news-edit",
				component: () => import("@/views/admin/news/NewsForm.vue"),
				meta: { parent: [{ text: "News", to: { name: "admin-news" } }], title: "Edit News" },
			},

			{
				path: "research",
				name: "admin-research",
				component: () => import("@/views/admin/news/research"),
				meta: { title: "Research" },
			},
			{
				path: "research/add",
				name: "research-add",
				component: () => import("@/views/admin/news/ResearchForm.vue"),
				meta: { parent: [{ text: "News", to: { name: "admin-research" } }], title: "Add New" },
			},
			{
				path: "research/edit/:id",
				name: "research-edit",
				component: () => import("@/views/admin/news/ResearchForm.vue"),
				meta: { parent: [{ text: "Research", to: { name: "admin-research" } }], title: "Edit" },
			},

			{
				path: "users",
				name: "admin-users",
				component: () => import("@/views/admin/users"),
				meta: { title: "Users" },
			},
			{
				name: "access-denied",
				path: "access-denied",
				component: () => import("@/views/admin/access-denied.vue"),
				meta: { title: "Access Denied" },
			},
		],
	},
	{
		path: "/about-us",
		name: "about-us",
		meta: { title: "About Us" },
		component: () => import("@/views/AboutUs.vue"),
	},
	{
		path: "/:catchAll(.*)",
		name: "not-found",
		component: () => import("@/views/NotFoundPage.vue"),
	},
];

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	mode: "hash",
	linkActiveClass: "active",
});

router.beforeEach(async function (_to, _from, next) {
	if (_to.name == "not-found") {
		next();
	} else {
		const auth = useAuth();
		if (P_ROUTER.includes(_to.name)) {
			next();
		} else {
			if (!auth.isLogin && localAuth.get()) {
				await auth.autoLogin();
			}
			if (auth.isLogin && auth.user?.isAdmin) {
				document.title = "IDO Calendar - Calendar of all projects IDO, ICO & IEO ….";
				if (_to.meta?.title) {
					document.title = `IDO Calendar - Calendar of all projects IDO, ICO & IEO | ${_to.meta?.title}`;
				}
				next();
			} else {
				next({ name: "home" });
			}
		}
	}
});

export default router;
