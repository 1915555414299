import "@babel/polyfill";
import "mutationobserver-shim";
import "./plugins/bootstrap-vue";
import "./components";
import { createApp } from "vue";
import App from "./AppLayout.vue";
import router from "./router";
import store from "./store";
import { BASE_URL, toUrlBase, toUrlBaseSave } from "./api";
import { createPinia } from "pinia";
import mUtils from "@/utils";

const pinia = createPinia();

const app = createApp(App);
app.use(store);
app.config.warnHandler = () => false;
app.config.globalProperties.BASE_URL = BASE_URL;
app.config.globalProperties.mUtils = mUtils;
app.config.globalProperties.toUrlBase = toUrlBase;
app.config.globalProperties.toUrlBaseSave = toUrlBaseSave;
app.config.globalProperties.toUrlChain = mUtils.toUrlChain;

app.use(router);
app.use(pinia);
app.mount("#app");
