import { toast } from "vue3-toastify";

export default () => ({
	success: () => {
		toast.success("Successfull", { autoClose: 1000 });
	},
	warn: (message) => {
		toast.warn(message, { autoClose: 1000 });
	},
});
