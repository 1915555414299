import { defineStore } from "pinia";
import { useRoute } from "vue-router";

export default defineStore("utils", {
	state: () => ({
		breadcrumb: [],
		title: "",
		projectTypeActive: "active",
	}),
	getters: {},
	actions: {
		updateTitle(title) {
			this.title = title;
		},
		updateBreadcrumb(meta) {
			if (!meta) {
				const route = useRoute();
				meta = route.meta;
			}
			if (meta?.title) {
				this.title = meta.title;
			}
			this.breadcrumb = [
				{
					text: "Home",
					to: { name: "home" },
				},
				...(meta?.parent || []),
				{
					text: meta?.title,
					active: true,
				},
			];
		},
		updateProjectActive(active) {
			this.projectTypeActive = active;
		},
	},
});
