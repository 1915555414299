<template>
	<b-sidebar :id="`${props.myId}-project-filter`" right lazy no-header backdrop class="my-sidebar">
		<template #default="{ hide }">
			<div class="filter-wrap">
				<div class="filter-header">
					<b-icon-x class="rounded-circle bg-white btn-filter-close" @click="hide" font-scale="1.5" />
					<b-icon-caret-up-fill class="caret-up" />
				</div>
				<div class="filter-content">
					<div class="d-flex title p-3">
						<div class="mr-auto">Filter</div>
					</div>
					<div class="px-3 pb-3 filter-body">
						<div class="ido-dropdown">
							<label class="label">Category</label>
							<select class="custom-select" v-model="filter[props.myId].category">
								<option value="">-- ALL --</option>
								<option v-for="item in filter.categories" :key="item.key" :value="item.key">
									{{ item.name }}
								</option>
							</select>
						</div>
						<!-- <i-dropdown
							title="Category"
							mKey="category"
							:options="filter.categories"
							v-model="filter[props.myId].category"
							:mId="props.myId"
							@onChange="filter.changeValue"
						/> -->
						<!-- <i-dropdown
							title="Chain"
							mKey="chain"
							:options="filter.chains"
							v-model="filter[props.myId].chain"
							:mId="props.myId"
							@onChange="filter.changeValue"
						/> -->

						<div class="ido-dropdown">
							<label class="label">Chain</label>
							<select class="custom-select" v-model="filter[props.myId].chain">
								<option value="">-- ALL --</option>
								<option v-for="item in filter.chains" :key="item.key" :value="item.key">
									{{ item.name }}
								</option>
							</select>
						</div>
						<!-- <i-dropdown
							title="Stage"
							mKey="stage"
							:options="filter.stages"
							v-model="filter[props.myId].stage"
							:mId="props.myId"
							@onChange="filter.changeValue"
						/> -->
						<div class="ido-dropdown">
							<label class="label">Stage</label>
							<select class="custom-select" v-model="filter[props.myId].stage">
								<option value="">-- ALL --</option>
								<option v-for="item in filter.stages" :key="item.key" :value="item.key">
									{{ item.name }}
								</option>
							</select>
						</div>
						<b-form-group label-for="txt-start-at" class="mb-0 ido-date">
							<template #label><span class="t-label">Start At</span></template>
							<b-form-input
								class="ip-date"
								type="date"
								id="txt-start-at"
								placeholder="Start At"
								v-model="filter[props.myId].startAt"
								trim
							></b-form-input>
						</b-form-group>
						<b-form-group label-for="txt-end-at" class="ido-date">
							<template #label><span class="t-label">End At</span></template>
							<b-form-input
								type="date"
								id="txt-end-at"
								placeholder="End At"
								v-model="filter[props.myId].endAt"
								class="ip-date"
								trim
							></b-form-input>
						</b-form-group>
					</div>
					<div class="d-flex justify-content-end p-3">
						<b-button class="w-100 btn-ido-primary btn-white" @click="onApply($emit, hide)">Apply</b-button>
					</div>
				</div>
			</div>
		</template>
	</b-sidebar>
</template>
<script setup>
import { onMounted, defineProps } from "vue";
import { useFilter } from "@/uses";

const filter = useFilter();

const props = defineProps(["myId"]);

function onApply($emit, hide) {
	if ("home" === props.myId) $emit("onApply", null, filter.hParams);
	else if ("watch" === props.myId) $emit("onApply", null, filter.wParams);
	hide();
}

onMounted(() => {
	if (filter.categories && filter.categories.length === 0) filter.fetchCategories();
});

// function onChange({ target: { value } }, key) {
// 	// console.log(key, value, props.myId);
// 	// filter.changeValue(key, value, props.myId);
// }
</script>

<style lang="scss" scoped>
#home-project-filter,
#watch-project-filter {
	background: transparent;
	.filter-body {
		display: flex;
		gap: 1rem;
		flex-direction: column;
		.ido-dropdown {
			.label {
				color: var(--color-white);
			}
			.custom-select {
				background-color: transparent;
			}
		}
		.ido-date {
			.t-label {
				font-size: 0.875rem;
				font-weight: 400;
				color: var(--color-white) !important;
				color: #c384d8;
			}
			.ip-date {
				background-color: transparent;
				border-color: #c384d8;
				color: #c384d8;
				::-webkit-calendar-picker-indicator {
					color: #c384d8;
				}
				::after {
					content: "\1F4C5";
				}
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				color: #c384d8;
				transition: var(--trans-out);

				&::-webkit-calendar-picker-indicator {
					filter: invert(100%);
					transition: var(--trans-out);
				}
			}
			.custom-select {
				background-color: transparent;
				border-color: #c384d8;
				color: #c384d8;
			}
		}
	}
}
</style>
