<template>
	<ido-content :title="route?.meta?.title">
		<template #menu-item>
			<div
				class="menu-left-item"
				v-for="n in navs"
				:key="n.key"
				@click="onActive(n.key)"
				:class="{ active: state.active == n.key, collapse: collapse.active }"
			>
				<b-icon :icon="n.icon" class="icon" v-if="collapse.active" v-b-tooltip.hover :title="n.title" />
				<b-icon :icon="n.icon" class="icon" v-else />
				<span v-if="!collapse.active">{{ n.title }} </span>
			</div>
		</template>
		<template #body>
			<div class="project-wrap page-content px-0 mx-0">
				<b-breadcrumb class="admin-breacrumb mb-3 pt-2 px-0" :items="utils.breadcrumb"></b-breadcrumb>
				<router-view />
			</div>
		</template>
	</ido-content>
</template>
<script setup>
import { useCollapse, useSys, useUtils } from "@/uses";
import { computed, onBeforeMount, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { navList } from "@/utils";

const collapse = useCollapse();
const utils = useUtils();
const route = useRoute();
const router = useRouter();
const usys = useSys();
const navs = computed(() => navList[1].childrens);

const state = reactive({ active: route.name });
function onActive(name) {
	router.push({ name });
	state.active = name;
	usys.onUpdateTitle();
}

onBeforeMount(utils.updateBreadcrumb);
</script>
<style lang="scss" scoped>
.page-content {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: calc(100vh - var(--nav-height) - 1rem);
	overflow-y: auto;
	padding: 1rem;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		background-color: #5e274e;
		width: 0.25rem;
		border-radius: 0.125rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-white);
		border-radius: 0.125rem;
	}
	@media only screen and (max-width: 768px) {
		height: calc(100vh - var(--nav-mheight) - 0.5rem);
	}
}
</style>
