import Api, { authHeader } from "./base.api";

export default {
	get(url, config) {
		if (!config) config = {};
		if (!config.headers) config.headers = {};
		config.headers = { ...config.headers, ...authHeader.get() };
		return Api.get(url, config);
	},
	post(url, data, config) {
		if (!config) config = {};
		if (!config.headers) config.headers = {};
		config.headers = { ...config.headers, ...authHeader.get() };
		return Api.post(url, data, config);
	},
	put(url, data, config) {
		if (!config) config = {};
		if (!config.headers) config.headers = {};
		config.headers = { ...config.headers, ...authHeader.get() };
		return Api.put(url, data, config);
	},
	delete(url, config) {
		if (!config) config = {};
		if (!config.headers) config.headers = {};
		config.headers = { ...config.headers, ...authHeader.get() };
		return Api.delete(url, config);
	},
};
