import utils from "../utils";
import Api, { authHeader, toEndPoint } from "./base.api";

const END_POINT = "public";

export default {
	async suggests(params, headers) {
		try {
			let _header = headers || {};
			const tmp = authHeader.get();
			let { data } = await Api.get(toEndPoint(END_POINT, "suggests"), {
				params,
				headers: { ..._header, ...tmp },
			});
			data = utils.decode(data);
			return data;
		} catch (error) {
			return [];
		}
	},
	async projects(params, headers) {
		try {
			let _header = headers || {};
			const tmp = authHeader.get();
			let { data } = await Api.get(toEndPoint(END_POINT, "projects"), {
				params,
				headers: { ..._header, ...tmp },
			});
			data = utils.decode(data);
			return data;
		} catch (error) {
			return { total: 0, data: [], favorites: [] };
		}
	},
	async watchProject(params, headers) {
		try {
			let _header = headers || {};
			const tmp = authHeader.get();
			let { data } = await Api.get(toEndPoint(END_POINT, "watch"), {
				params,
				headers: { ..._header, ...tmp },
			});
			data = utils.decode(data);
			return data;
		} catch (error) {
			return { total: 0, data: [], favorites: [] };
		}
	},
	// async research(params, headers) {
	// 	let _header = headers || {};
	// 	const tmp = authHeader.get();
	// 	_header["x-type"] = "active";
	// 	let { data } = await Api.get(toEndPoint(END_POINT, "search"), {
	// 		params,
	// 		headers: { ..._header, ...tmp },
	// 	});
	// 	data = utils.decode(data);
	// 	return data;
	// },

	async news(params, headers) {
		let _header = headers || {};
		const tmp = authHeader.get();
		let { data } = await Api.get(toEndPoint(END_POINT, "news"), {
			params,
			headers: { ..._header, ...tmp },
		});
		data = utils.decode(data);
		return data;
	},
	async research(params, headers) {
		let _header = headers || {};
		const tmp = authHeader.get();
		let { data } = await Api.get(toEndPoint(END_POINT, "research"), {
			params,
			headers: { ..._header, ...tmp },
		});
		data = utils.decode(data);
		return data;
	},
	async newsSlug(slug) {
		const { data } = await Api.get(toEndPoint(END_POINT, "new/" + slug));
		return utils.decode(data);
	},
	async categories(params, headers) {
		if (params && !params.pageSize) {
			params.pageSize = 1000;
		}
		let { data } = await Api.get(toEndPoint(END_POINT, "categories"), {
			params,
			headers: headers || {},
		});

		return utils.decode(data);
	},
	async projectBySlug(id) {
		let headers = {};
		const tmp = authHeader.get();
		const { data } = await Api.get(toEndPoint(END_POINT, "project/" + id), {
			params: {},
			headers: { ...headers, ...tmp },
		});
		return utils.decode(data);
	},
	gasInfo() {
		return Api.get(toEndPoint(END_POINT, "global"));
	},
	async favorites(params) {
		const headers = authHeader.get();
		const { data } = await Api.get(toEndPoint(END_POINT, "favorites"), {
			params,
			headers,
		});
		return utils.decode(data);
	},
};
