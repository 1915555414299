<template>
	<b-modal :id="`modal-socials${props.myId || ''}`" title="Select Socials" @change="onChange" @hidden="onChange">
		<m-checkbox @input="onSelected" :options="share.socialsList" :dfSelect="state.selected || []" />
		<template #modal-footer="{ hide }">
			<div>
				<b-btn @click="hide" variant="warning" class="mr-3">Cancel</b-btn>
				<b-btn variant="info" class="px-3" @click="onSuccess($event, $emit, hide)">OK</b-btn>
			</div>
		</template>
	</b-modal>
</template>
<script setup>
import { reactive, defineProps, onMounted } from "vue";
import share from "@/utils/share";

const props = defineProps(["sList", "myId"]);

const state = reactive({ selected: [] });

function onChange(e) {
	if (e) {
		state.selected = props?.sList || [];
	}
}

function onSuccess($event, $emit, hide) {
	$emit("onSuccess", state.selected);
	hide();
}

function onSelected(val) {
	state.selected = val;
}

onMounted(() => {});
</script>
<style lang="scss">
#modal-socials,
#modal-social-info {
	.modal-body {
		padding-top: 1rem;
	}
}
</style>
