import { localAuth } from "@/utils/local";
import utils from "../utils";
import Api, { BASE_URL } from "./base.api";

const END_POINT = "auth";

function toEndPoint(endPoint) {
	return `${END_POINT}/${endPoint}`;
}

export default {
	async login(params, headers) {
		let { data } = await Api.post(toEndPoint("login"), params, {
			headers: headers || {},
		});
		return utils.decode(data);
	},
	async gLogin(token) {
		let { data } = await Api.get(toEndPoint("login"), {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return utils.decode(data);
	},
	async me(token) {
		try {
			let { data } = await Api.get(toEndPoint("me"), {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return utils.decode(data);
		} catch (error) {
			localAuth.del();
			return null;
		}
	},
	async autoLogin() {
		try {
			const token = localAuth.get();
			if (token) {
				const rs = this.me(token);
				if (rs && rs.avatar && !rs.avatar.startsWith("http")) {
					rs.avatar = `${BASE_URL}${rs.avatar}`;
				}
				return rs;
			}
			return null;
		} catch (error) {
			localAuth.del();
			return null;
		}
	},
};
