const solana = `https://api.idocalendar.com/files/chains/2024070804-idosolana-sol.png`;
const chains = {
	ethereum: "https://api.idocalendar.com/files/chains/ethereum.jpg",
	ubiq: "https://api.idocalendar.com/files/chains/ubiq.jpg",
	optimism: "https://api.idocalendar.com/files/chains/optimism.jpg",
	flare: "https://api.idocalendar.com/files/chains/flare.jpg",
	songbird: "https://api.idocalendar.com/files/chains/songbird.jpg",
	elastos: "https://api.idocalendar.com/files/chains/elastos.jpg",
	kardiachain: "https://api.idocalendar.com/files/chains/kardiachain.jpg",
	cronos: "https://api.idocalendar.com/files/chains/cronos.jpg",
	rsk: "https://api.idocalendar.com/files/chains/rsk.jpg",
	telos: "https://api.idocalendar.com/files/chains/telos.jpg",
	lukso: "https://api.idocalendar.com/files/chains/lukso.jpg",
	xdc: "https://api.idocalendar.com/files/chains/xdc.jpg",
	csc: "https://api.idocalendar.com/files/chains/csc.jpg",
	zyx: "https://api.idocalendar.com/files/chains/zyx.jpg",
	binance: "https://api.idocalendar.com/files/chains/binance.jpg",
	syscoin: "https://api.idocalendar.com/files/chains/syscoin.jpg",
	gochain: "https://api.idocalendar.com/files/chains/gochain.jpg",
	ethereumclassic: "https://api.idocalendar.com/files/chains/ethereumclassic.jpg",
	okexchain: "https://api.idocalendar.com/files/chains/okexchain.jpg",
	hoo: "https://api.idocalendar.com/files/chains/hoo.jpg",
	meter: "https://api.idocalendar.com/files/chains/meter.jpg",
	"nova network": "https://api.idocalendar.com/files/chains/nova network.jpg",
	viction: "https://api.idocalendar.com/files/chains/viction.jpg",
	xdai: "https://api.idocalendar.com/files/chains/xdai.jpg",
	velas: "https://api.idocalendar.com/files/chains/velas.jpg",
	thundercore: "https://api.idocalendar.com/files/chains/thundercore.jpg",
	enuls: "https://api.idocalendar.com/files/chains/enuls.jpg",
	fuse: "https://api.idocalendar.com/files/chains/fuse.jpg",
	heco: "https://api.idocalendar.com/files/chains/heco.jpg",
	polygon: "https://api.idocalendar.com/files/chains/polygon.jpg",
	shimmer_evm: "https://api.idocalendar.com/files/chains/shimmer_evm.jpg",
	manta: "https://api.idocalendar.com/files/chains/manta.jpg",
	xdaiarb: "https://api.idocalendar.com/files/chains/xdaiarb.jpg",
	op_bnb: "https://api.idocalendar.com/files/chains/op_bnb.jpg",
	energyweb: "https://api.idocalendar.com/files/chains/energyweb.jpg",
	oasys: "https://api.idocalendar.com/files/chains/oasys.jpg",
	fantom: "https://api.idocalendar.com/files/chains/fantom.jpg",
	hpb: "https://api.idocalendar.com/files/chains/hpb.jpg",
	boba: "https://api.idocalendar.com/files/chains/boba.jpg",
	omax: "https://api.idocalendar.com/files/chains/omax.jpg",
	filecoin: "https://api.idocalendar.com/files/chains/filecoin.jpg",
	kucoin: "https://api.idocalendar.com/files/chains/kucoin.jpg",
	era: "https://api.idocalendar.com/files/chains/era.jpg",
	shiden: "https://api.idocalendar.com/files/chains/shiden.jpg",
	theta: "https://api.idocalendar.com/files/chains/theta.jpg",
	pulse: "https://api.idocalendar.com/files/chains/pulse.jpg",
	sx: "https://api.idocalendar.com/files/chains/sx.jpg",
	areon: "https://api.idocalendar.com/files/chains/areon.jpg",
	candle: "https://api.idocalendar.com/files/chains/candle.jpg",
	rollux: "https://api.idocalendar.com/files/chains/rollux.jpg",
	astar: "https://api.idocalendar.com/files/chains/astar.jpg",
	callisto: "https://api.idocalendar.com/files/chains/callisto.jpg",
	wanchain: "https://api.idocalendar.com/files/chains/wanchain.jpg",
	conflux: "https://api.idocalendar.com/files/chains/conflux.jpg",
	metis: "https://api.idocalendar.com/files/chains/metis.jpg",
	polygon_zkevm: "https://api.idocalendar.com/files/chains/polygon_zkevm.jpg",
	core: "https://api.idocalendar.com/files/chains/core.jpg",
	ultron: "https://api.idocalendar.com/files/chains/ultron.jpg",
	step: "https://api.idocalendar.com/files/chains/step.jpg",
	moonbeam: "https://api.idocalendar.com/files/chains/moonbeam.jpg",
	moonriver: "https://api.idocalendar.com/files/chains/moonriver.jpg",
	"living assets mainnet": "https://api.idocalendar.com/files/chains/living assets mainnet.jpg",
	tenet: "https://api.idocalendar.com/files/chains/tenet.jpg",
	onus: "https://api.idocalendar.com/files/chains/onus.jpg",
	hubblenet: "https://api.idocalendar.com/files/chains/hubblenet.jpg",
	sanko: "https://api.idocalendar.com/files/chains/sanko.jpg",
	dogechain: "https://api.idocalendar.com/files/chains/dogechain.jpg",
	kava: "https://api.idocalendar.com/files/chains/kava.jpg",
	soma: "https://api.idocalendar.com/files/chains/soma.jpg",
	beam: "https://api.idocalendar.com/files/chains/beam.jpg",
	iotex: "https://api.idocalendar.com/files/chains/iotex.jpg",
	mantle: "https://api.idocalendar.com/files/chains/mantle.jpg",
	xlc: "https://api.idocalendar.com/files/chains/xlc.jpg",
	nahmii: "https://api.idocalendar.com/files/chains/nahmii.jpg",
	bouncebit: "https://api.idocalendar.com/files/chains/bouncebit.jpg",
	tombchain: "https://api.idocalendar.com/files/chains/tombchain.jpg",
	planq: "https://api.idocalendar.com/files/chains/planq.jpg",
	bitrock: "https://api.idocalendar.com/files/chains/bitrock.jpg",
	cyeth: "https://api.idocalendar.com/files/chains/cyeth.jpg",
	canto: "https://api.idocalendar.com/files/chains/canto.jpg",
	klaytn: "https://api.idocalendar.com/files/chains/klaytn.jpg",
	base: "https://api.idocalendar.com/files/chains/base.jpg",
	jbc: "https://api.idocalendar.com/files/chains/jbc.jpg",
	evmos: "https://api.idocalendar.com/files/chains/evmos.jpg",
	carbon: "https://api.idocalendar.com/files/chains/carbon.jpg",
	smartbch: "https://api.idocalendar.com/files/chains/smartbch.jpg",
	loop: "https://api.idocalendar.com/files/chains/loop.jpg",
	genesys: "https://api.idocalendar.com/files/chains/genesys.jpg",
	eos_evm: "https://api.idocalendar.com/files/chains/eos_evm.jpg",
	blast: "https://api.idocalendar.com/files/chains/blast.jpg",
	bitgert: "https://api.idocalendar.com/files/chains/bitgert.jpg",
	fusion: "https://api.idocalendar.com/files/chains/fusion.jpg",
	zilliqa: "https://api.idocalendar.com/files/chains/zilliqa.jpg",
	arbitrum: "https://api.idocalendar.com/files/chains/arbitrum.jpg",
	arbitrum_nova: "https://api.idocalendar.com/files/chains/arbitrum_nova.jpg",
	celo: "https://api.idocalendar.com/files/chains/celo.jpg",
	oasis: "https://api.idocalendar.com/files/chains/oasis.jpg",
	etherlink: "https://api.idocalendar.com/files/chains/etherlink.jpg",
	avalanche: "https://api.idocalendar.com/files/chains/avalanche.jpg",
	rei: "https://api.idocalendar.com/files/chains/rei.jpg",
	reichain: "https://api.idocalendar.com/files/chains/reichain.jpg",
	linea: "https://api.idocalendar.com/files/chains/linea.jpg",
	bob: "https://api.idocalendar.com/files/chains/bob.jpg",
	godwoken: "https://api.idocalendar.com/files/chains/godwoken.jpg",
	chiliz: "https://api.idocalendar.com/files/chains/chiliz.jpg",
	taiko: "https://api.idocalendar.com/files/chains/taiko.jpg",
	btr: "https://api.idocalendar.com/files/chains/btr.jpg",
	polis: "https://api.idocalendar.com/files/chains/polis.jpg",
	kekchain: "https://api.idocalendar.com/files/chains/kekchain.jpg",
	vision: "https://api.idocalendar.com/files/chains/vision.jpg",
	neon: "https://api.idocalendar.com/files/chains/neon.jpg",
	aurora: "https://api.idocalendar.com/files/chains/aurora.jpg",
	harmony: "https://api.idocalendar.com/files/chains/harmony.jpg",
	palm: "https://api.idocalendar.com/files/chains/palm.jpg",
	curio: "https://api.idocalendar.com/files/chains/curio.jpg",
	solana,
	"zksync era": "/icons/zksync-era.png",
	bitlayer: "/icons//bitlayer.png",
	aleo: "https://api.idocalendar.com/files/ido-20240711-9ec129d67667c7c739014213457c22ec.webp",
	ton: "https://api.idocalendar.com/files/chains/2024073109-idotoncoin-ton-logo.png",
};

export function toUrlChain(name) {
	if (!name) "/icons/unknown-logo.png";
	name = name.toLowerCase();

	if (`${name}`.indexOf(",") > 0) {
		return "/icons/multichain.png";
	}

	switch (name) {
		case "binance smart chain":
		case "bnb":
		case "bnb chain":
			name = "binance";
			break;

		case "arbitrum(arb one)":
			name = "arbitrum";
			break;
		case "avalanche c-chain":
			name = "avalanche";
			break;
		case "base chain":
			name = "base";
			break;

		default:
			break;
	}
	if (chains[name]) return chains[name];

	return "/icons/unknown-logo.png";
}
