import axios from "axios";
import { localAuth } from "@/utils/local";
const IS_LOCAL = false;

// export const BASE_URL = IS_LOCAL ? "http://localhost:4000" : "https://ido.metaio.online";
export const BASE_URL = IS_LOCAL ? "http://localhost:4000" : "https://api.idocalendar.com";

export function toEndPoint(endPoint, id) {
	if (id) return `${endPoint}/${id}`;
	return `${endPoint}`;
}
export default axios.create({
	baseURL: BASE_URL,
});

export const authHeader = {
	get: function () {
		const token = localAuth.get();
		return {
			Authorization: `Bearer ${token}`,
		};
	},
};
