<template>
	<div class="ts-wrap">
		<div class="ts-toolbar text-right">
			<b-btn class="btn-ido-primary btn-white ml-auto outline" v-b-toggle.sb-token-sale-add>Add Token Sale</b-btn>
			<TokenSaleAdd :item="state.item" @onSuccess="onAddSuccess" :add="state.add" />
		</div>
		<tb-empty v-if="!state.data || (state.data && state.data.length == 0)"></tb-empty>
		<template v-else v-for="(item, inx) in state.data" :key="`key-${inx}`">
			<div class="ts-card my-3">
				<div class="ts-title p-3 d-flex">
					{{ toTitle(item) }}
					<div class="tb-action ml-auto" v-if="true">
						<b-icon-pencil-square variant="info" class="a-btn" @click="onAdd($root, item, inx)" />
						<span>|</span>
						<b-icon-trash class="a-btn" variant="danger" :id="`ts-popover-${inx}`" />
						<b-popover :target="`ts-popover-${inx}`" title="Confirm" triggers="click">
							<div class="text-center">
								<div>Are you sure you want to delete this item?</div>
								<div class="d-flex mt-2 justify-content-end">
									<b-btn
										variant="danger"
										size="sm"
										class="px-2"
										@click="$root.$emit('bv::hide::popover')"
										>Cancel</b-btn
									>
									<b-btn @click="onDel($event, inx, $root)" variant="info" size="sm" class="px-4 ml-2"
										>OK</b-btn
									>
								</div>
							</div>
						</b-popover>
					</div>
				</div>
				<b-row class="text-center py-3">
					<b-col>
						<div class="tsc-title">Sale Price:</div>
						<div class="tsc-title value">{{ item?.tPrice || "" }}</div>
					</b-col>
					<b-col>
						<div class="tsc-title">Raise:</div>
						<div class="tsc-title value">{{ item?.tRaise || "" }}</div>
					</b-col>
					<b-col>
						<div class="tsc-title">Launchpad:</div>
						<div class="tsc-title value m-cursor" @click="share.openLink(item?.tlpLink)">
							{{ item?.tlpName || "" }}
						</div>
					</b-col>
					<b-col>
						<div class="tsc-title">Tokens For Sale:</div>
						<div class="tsc-title value">{{ item?.tSupply }}</div>
					</b-col>
				</b-row>
			</div>
		</template>
	</div>
</template>

<script setup>
import moment from "moment";
import { defineEmits, reactive, defineProps, onUpdated } from "vue";
import share from "../../../utils/share";
import TokenSaleAdd from "./TokenSaleAdd";
let props = defineProps(["value"]);

const $emit = defineEmits(["update"]);

let state = reactive({
	data: [],
	busy: true,
	item: null,
	add: null,
});

setTimeout(() => (state.busy = false), 1000);

function toTitle({ tStage, tStartAt, tEndAt }) {
	if (tStartAt && tEndAt) {
		const now = moment();

		let status = "";
		const s = moment(tStartAt);
		const e = moment(tEndAt);
		if (now > e) {
			status = "";
		}
		if (now < e && now > s) {
			status = "";
		}

		if (s > now) status = "";

		if (tStage) {
			tStartAt = moment(tStartAt);
			tEndAt = moment(tEndAt);
			if (tStartAt.year() == tEndAt.year()) {
				return `${tStage}${status}: ${tStartAt.format("MMM DD")} - ${tEndAt.format("MMM DD, YYYY")}`;
			} else return `${tStage}${status}: ${tStartAt.format("MMM DD, YYYY")} - ${tEndAt.format("MMM DD, YYYY")}`;
		}

		return "";
	}

	if (tStage) return `${tStage}: TBA`;

	return "";
}

onUpdated(() => {
	if (props.value && state.data && state.data.length == 0) {
		state.data = props.value || [];
	}
});

function onDel($event, indexToRemove, $root) {
	state.data = state.data.slice(0, indexToRemove).concat(state.data.slice(indexToRemove + 1));
	$emit("update", state.data);
	if ($root) $root.$emit("bv::hide::popover");
}

function onAdd($root, item, inx) {
	$root.$emit("bv::toggle::collapse", "sb-token-sale-add");
	state.item = item;
	state.add = inx;
}
function onAddSuccess(item, hide, add) {
	if (add === null) {
		state.data = [{ ...item }, ...state.data];
	} else {
		state.data[add] = { ...item };
	}
	$emit("update", state.data);
	hide();
	state.add = null;
	state.item = null;
}

// function onClick() {
// 	model.value = parseInt(model.value) + 1;
// 	console.log(model.value);
// }
</script>

<style lang="scss" scoped>
.ts-wrap {
	.ts-card {
		border: 1px solid var(--border-color);
		border-radius: 1rem !important;
		-webkit-border-radius: 1rem !important;
		-moz-border-radius: 1rem !important;
		border-radius: 1rem !important;
		-webkit-border-radius: 1rem !important;
		-moz-border-radius: 1rem !important;
		.ts-title {
			background: rgba(0, 0, 0, 0.2);
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 21px;
			font-family: var(--font-family);
			color: var(--text-color-primary);
			border-top-left-radius: 1rem !important;
			-webkit-border-top-left-radius: 1rem !important;
			-moz-border-top-left-radius: 1rem !important;
			border-top-right-radius: 1rem !important;
			-webkit-border-top-right-radius: 1rem !important;
			-moz-border-top-right-radius: 1rem !important;
		}
		.tsc-title {
			font-family: "DM Sans";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			font-family: var(--font-family);
			color: var(--text-color-primary);
			flex: none;
			flex-grow: 0;
			&.value {
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: #ba8dae;
			}
		}
		.tb-action {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
		}
	}
}
</style>
