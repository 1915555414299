import { defineStore } from "pinia";

const KEY_COLLAPSE = "nav-collapse";

export default defineStore(KEY_COLLAPSE, {
	state: () => {
		let active = false;
		const val = localStorage.getItem(KEY_COLLAPSE);
		if (val && val === "true") {
			active = true;
		}
		return {
			active,
		};
	},
	getters: {},
	actions: {
		onLoadConfig: function () {
			if (localStorage.getItem(KEY_COLLAPSE)) {
				this.active = true;
			}
		},
		onCollapse: function () {
			this.active = !this.active;
			localStorage.setItem(KEY_COLLAPSE, this.active);
		},
	},
});
