<template>
	<b-modal id="modal-socials2" :title="(props?.title || '') + ' Socials'" hide-footer>
		<div class="m-socials">
			<template v-for="(sItem, inx) in props.items" :key="`s-${inx}`">
				<div class="social-item m-action" @click="share.openLink(sItem.link, '_blank')">
					<b-avatar
						v-if="sItem.img"
						class="i-social"
						:src="sItem.img"
						size="1.5rem"
						v-b-tooltip.hover
						:title="sItem.text"
						@click="share.openLink(sItem.link, '_blank')"
						:style="`background: ${sItem.color}`"
					/>
					<b-icon
						v-else-if="sItem.icon"
						class="i-social"
						:icon="sItem.icon"
						font-scale="1.5"
						v-b-tooltip.hover
						:title="sItem.text"
						@click="share.openLink(sItem.link, '_blank')"
						:style="`color: ${sItem.color}`"
					/>
					<b-avatar
						v-else
						class="i-social"
						:text="sItem.text"
						size="1.5rem"
						v-b-tooltip.hover
						:title="sItem.text"
						:style="`background: ${sItem.color}`"
					/>
					<div class="brandname">{{ sItem.text }}</div>

					<!-- <b-avatar :src="toUrlBase(item.logo)" :alt="item.title"></b-avatar>
					<div class="brandname">{{ item.title }}</div>
					<div class="socials">
						<b-icon
							v-b-tooltip.hover
							title="Website"
							icon="link45deg"
							class="s-item border rounded rounded-circle"
							v-if="item.website"
							@click="share.openLink(item.website)"
						></b-icon>
						<template v-for="(it, sKey) in item.socials" :key="sKey">
							<b-icon
								v-b-tooltip.hover
								:title="sKey"
								class="s-item border rounded rounded-circle"
								v-if="it && icons[sKey] && icons[sKey].icon"
								@click="share.openLink(it)"
								:icon="icons[sKey].icon"
							></b-icon>
							<b-avatar
								v-else
								:alt="sKey"
								:text="`${sKey}`.substring(0, 1)"
								class="s-item av border rounded rounded-circle"
								@click="share.openLink(it)"
								v-b-tooltip.hover
								:title="sKey"
							></b-avatar>
						</template>
					</div> -->
				</div>
			</template>
		</div>
	</b-modal>
</template>
<script setup>
import share from "@/utils/share";
import { defineProps } from "vue";

const props = defineProps(["items", "title"]);
</script>

<style lang="scss">
#modal-socials2 {
	.modal-body {
		padding: 0px 1rem 1rem 1rem;
		.m-socials {
			display: flex;
			gap: 1rem;
			flex-direction: row;
			flex-wrap: wrap;
			padding-top: 1rem;
			justify-content: center;
			.social-item {
				min-width: 10rem;
				border: 1px solid var(--border-color);
				border-radius: 1rem;
				padding: 0.75rem 1.5rem;
				text-align: center;
				display: flex;
				gap: 0.5rem;
				flex-direction: row;
				justify-content: start;
				align-items: center;
				font-family: var(--font-family);
				.brandname {
					font-size: 1rem;
					font-weight: 400;
				}
			}
		}
	}
}
</style>
