<template>
	<div class="shadow px-3 py-3">
		<b-row class="text-left">
			<b-col cols="12" md="12">
				<b-form-group label="Title" label-for="txt-title">
					<b-form-input
						type="text"
						id="txt-title"
						placeholder="Title"
						:readonly="!props?.add"
						v-model="form.title"
						trim
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="12">
				<b-form-group label="Percentage" label-for="txt-percentage">
					<b-form-input
						id="txt-percentage"
						placeholder="Percentage"
						v-model="form.percentage"
						trim
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="12">
				<b-form-group label="Total" label-for="txt-total">
					<b-form-input
						id="txt-total"
						placeholder="Total"
						type="text"
						v-model="form.total"
						trim
					></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="12" md="12">
				<b-form-group label="Description" label-for="txt-description">
					<b-form-textarea
						id="txt-description"
						placeholder="Description"
						v-model="form.description"
						rows="3"
						max-rows="6"
						trim
					></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="text-right">
			<b-col>
				<b-btn
					@click="onSave"
					variant="primary"
					class="px-3 btn-ido-primary btn-white w-100"
					:disabled="state.busy"
				>
					<b-icon icon="circle-fill" animation="throb" v-if="state.busy"></b-icon>
					<b-icon-save v-else /> Save
				</b-btn>
			</b-col>
		</b-row>
	</div>
</template>

<script setup>
import { useToast } from "@/uses";
import { reactive, defineProps, defineEmits, onUpdated } from "vue";

const toast = useToast();
const props = defineProps(["item", "add"]);

const form = reactive({
	title: props.item?.title,
	percentage: toFloat(props.item?.percentage),
	description: props.item?.description,
	total: props.item?.total,
});
const state = reactive({ busy: false, id: null });
const $emit = defineEmits(["onSuccess"]);

function toFloat(val) {
	if (val) return parseFloat(`${val}`.replace(/[^0-9.]/g, ""));
	return "";
}

function onSave() {
	if (!form.title) {
		toast.warn("Data invalid!");
	} else $emit("onSuccess", form, props?.add);
}

onUpdated(() => {
	form.title = props.item?.title;
	form.percentage = toFloat(props.item?.percentage);
	form.description = props.item?.description;
	form.total = props.item?.total;
});
</script>
