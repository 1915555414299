<template>
	<ido-content :title="route?.meta?.title">
		<template #menu-item>
			<div
				class="menu-left-item fade-in"
				v-for="n in navProjects"
				:key="n.key"
				@click="onActive(n.key)"
				:class="{ active: xType === n.key, collapse: collapse.active }"
			>
				<b-icon :icon="n.icon" class="icon" v-if="collapse.active" v-b-tooltip.hover :title="n.title" />
				<b-icon :icon="n.icon" class="icon" v-else />
				<span v-if="!collapse.active">{{ n.title }} </span>
			</div>
		</template>
		<template #body>
			<div class="project-wrap page-content ml-lg-0 ml-md-3 mb-3 pr-3 fade-in">
				<div class="tb-toolbar" :class="{ collapse: collapse.active }">
					<div class="toolbar-wrap">
						<div :class="`nav-item ${filter.watch.tags ? 'active' : ''}`" :key="key" @click="onHotClick">
							Hot deal
							<b-img class="ml-1" src="@/assets/imgs/t-hot.svg" />
						</div>

						<div
							v-for="{ key, name } in catList"
							:class="`nav-item ${filter.watch.category === key ? 'active' : ''}`"
							:key="key"
							@click="onFilter(key)"
						>
							{{ name }}
						</div>
					</div>
				</div>
				<div class="fade-in tb-wrap">
					<b-table
						:fields="state.fields"
						:busy="busy"
						:items="items"
						hover
						class="tb-project ido-projects fade-in"
						responsive
						show-empty
						thead-class="tb-thead"
						tbody-class="tb-tbody fade-in"
						sticky-header
						no-border-collapse
					>
						<template #table-busy>
							<tb-loading table="true" />
						</template>
						<template #empty>
							<tb-empty />
						</template>

						<template #head(time)="{ label }">
							<div class="mr-2 ido-c-text">{{ label }}</div>
						</template>

						<template #head(name)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>

						<template #head(website)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>

						<template #head(saleInfo-launchpad)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>

						<template #head(softcap)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>
						<template #head(investors)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>
						<template #head(saleInfo-price)="{ label }">
							<div class="mr-2 ido-c-text">{{ label }}</div>
						</template>

						<template #head(chain-name)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>

						<template #head(stage-name)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>
						<template #head(category-name)="{ label }">
							<div class="ido-c-text">{{ label }}</div>
						</template>

						<template #cell(chain-name)="{ item }">
							<template v-if="item?.chain?.name">
								<b-img
									v-if="item?.chain?.logo"
									class="ic-chain"
									:src="toUrlBaseSave(item?.chain?.logo, item?.chain?.name)"
									v-b-tooltip.hover
									:title="item?.chain?.name"
								/>
								<b-img
									v-else
									class="ic-chain"
									:src="toUrlChain(item?.chain?.name)"
									v-b-tooltip.hover
									:title="item?.chain?.name"
								/>
							</template>
						</template>

						<template #cell(stage-name)="{ item }">
							<div>{{ item?.stage?.name || "" }}</div>
						</template>
						<template #cell(category-name)="{ item }">
							<div>{{ item?.category?.name || "" }}</div>
						</template>
						<template #cell(saleInfo-price)="{ item }">
							<div>{{ item?.saleInfo?.price || "" }}</div>
						</template>

						<template #cell(favorite)="{ item }">
							<b-icon
								class="m-action mx-2"
								:color="item.favorite ? '#ffc107' : '#BFBFBF'"
								:icon="item.favorite ? 'star-fill' : 'star'"
								v-b-tooltip.hover
								title="Add to watchlist"
								@click="store.onWatchProject(item)"
							/>
						</template>
						<template #cell(name)="{ item }">
							<b-link
								class="m-action m-name"
								@click="goDetail(item)"
								:to="{ name: 'w-project-detail', params: { slug: item.slug } }"
							>
								<b-img v-if="item?.logo" class="pro-icon" :src="toUrlBase(item.logo)" />
								<div class="pro-title">
									{{ item.name }}
									<span v-if="item?.symbol" class="symbol mr-1">{{ item?.symbol }}</span>
									<b-img
										v-if="item?.tags?.includes('Hot')"
										src="@/assets/imgs/hot.svg"
										class="mr-1"
									/>
								</div>
							</b-link>
						</template>

						<template #emptyfiltered="scope">
							<h4>{{ scope.emptyFilteredText }}</h4>
						</template>
						<template #cell(time)="{ item }">
							<div class="mr-2">{{ utils.toProjectDate(item, xType) }}</div>
						</template>
						<template #cell(website)="{ item }">
							<div v-if="item.website" class="m-cursor" @click="share.openLink(item.website)">
								<b-icon-box-arrow-in-up-right color="#fff" />
							</div>
						</template>

						<template #cell(saleInfo-launchpad)="{ item }">
							<div class="d-flex justify-content-start align-items-center m-cursor">
								<m-link
									:item="item?.saleInfo?.launchpad"
									mTitle="lp_title"
									mLink="lp_link"
									mLogo="lp_logo"
								></m-link>
							</div>
						</template>

						<template #cell(investors)="{ item }">
							<div
								v-if="item?.investors && item?.investors?.length > 0"
								class="d-flex justify-content-center align-items-center m-cursor"
								@click="onInvestorShow($bvModal, item)"
							>
								<template v-if="item?.investors?.length > 1">
									<b-avatar-group size="1.5rem" class="investor-list">
										<template
											v-for="(inv, ind) in toItemSlice(item?.investors)"
											:key="`inv-li-${ind}`"
										>
											<b-avatar
												:src="toUrlBase(inv?.logo)"
												v-b-tooltip.hover
												:title="inv?.title"
												class="logo"
												size="1.5rem"
											/>
										</template>
										<b-avatar
											class="logo"
											size="1.5rem"
											variant="primary"
											v-if="item.investors.length > MAXITEM + 1"
											:text="`+${
												item.investors.length - MAXITEM < 10
													? item.investors.length - MAXITEM
													: 9
											}`"
										></b-avatar>
									</b-avatar-group>
								</template>
								<template v-else-if="item?.investors?.length === 1">
									<b-avatar
										v-if="item?.investors[0]?.logo"
										:src="toUrlBase(item?.investors[0]?.logo)"
										v-b-tooltip.hover
										:title="item?.investors[0]?.title"
										class="logo"
										size="1.5rem"
									/>
								</template>
							</div>
							<div v-else></div>
						</template>
					</b-table>
					<b-row class="align-items-center tb-footer mx-2 pt-2 fade-in" v-if="total > 0">
						<b-col cols="12" md="4" class="text-left py-1 page-info p-font">
							<div>Showing {{ pageFrom }} - {{ pageTo }} of {{ total }}</div>
						</b-col>
						<b-col cols="12" md="8" class="align-items-center align-self-center">
							<div class="d-flex justify-content-md-end justify-content-start" v-if="total > pageSize">
								<b-pagination
									right
									first-number
									last-number
									v-model="page"
									:total-rows="total"
									:per-page="pageSize"
									@change="(page) => onChangePage(page)"
									class="my-0 tb-page mb-md-0 mb-3"
									size="sm"
									pills
									variant="primary"
								></b-pagination>
							</div>
						</b-col>
					</b-row>
				</div>
				<ProjectFilter myId="watch" @onApply="store.onFilter" />
				<InvestorModal :title="state?.item?.name" :items="state?.item?.investors" />
			</div>
		</template>
	</ido-content>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { computed, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useFilter, useUtils, useCollapse, useWatch } from "@/uses";
import InvestorModal from "@/views/project/InvestorModal.vue";
import ProjectFilter from "@/views/project/ProjectFilter.vue";
import utils, { navProjects } from "@/utils";
import share from "@/utils/share";

const collapse = useCollapse();

const fields = [
	{ key: "favorite", label: "", class: "text-center" }, // stickyColumn: true },
	{
		key: "name",
		label: "Project",
		class: "text-left",
		thClass: "tb-th-text",
		tdClass: "tb-td-text f-700 single-line c-blue",
		// stickyColumn: true,
	},
	{
		key: "chain-name",
		label: "Chain",
		class: "text-left",
		thClass: "tb-th-text",
		tdClass: "tb-td-text text-center f-400 single-line",
	},
	{
		key: "stage-name",
		label: "Stage",
		class: "text-left",
		thClass: "tb-th-text",
		tdClass: "tb-td-text f-400 single-line",
	},
	{
		key: "category-name",
		label: "Category",
		class: "text-left",
		thClass: "tb-th-text",
		tdClass: "tb-td-text f-700 single-line c-blue",
	},
	// {
	// 	key: "website",
	// 	label: "Website",
	// 	class: "text-center",
	// 	thClass: "tb-th-text",
	// 	tdClass: "tb-td-text",
	// },
	{
		key: "saleInfo-launchpad",
		label: "Launchpad",
		class: "text-left",
		thClass: "tb-th-text text-left",
		tdClass: "tb-td-text f-500 c-blue single-line",
	},
	{
		key: "investors",
		label: "Investor",
		class: "text-left",
		thClass: "tb-th-text text-left",
		tdClass: "tb-td-text text-left f-500 c-blue single-line",
	},
	{
		key: "saleInfo-price",
		label: "Price",
		class: "text-right",
		thClass: "tb-th-text",
		tdClass: "tb-td-text f-700 single-line c-blue",
	},
	{
		key: "softcap",
		label: "Soft Cap",
		class: "text-right",
		thClass: "tb-th-text thw-3",
		tdClass: "tb-td-text f-700 single-line",
	},
];
const route = useRoute();
const store = useWatch();
const filter = useFilter();
const utiStore = useUtils();
const state = reactive({
	fields,
	item: null,
});

const { items, total, busy, page, pageSize, pageTo, pageFrom, xType } = storeToRefs(store);

function initCols(key) {
	let clz = "thw-2";
	let label = "";
	switch (key) {
		case "active":
			label = "End";
			break;
		case "upcoming":
			label = "Start";
			break;
		case "ended":
			label = "Sales Period";
			clz = "thw-5";
			break;
	}

	if (label)
		state.fields = [
			...fields,
			{
				key: "time",
				label,
				class: "text-right",
				thClass: `tb-th-text ${clz} pr-2`,
				tdClass: "tb-td-text f-400 single-line",
			},
		];
	else state.fields = fields;
}

function onHotClick() {
	const temp = filter.watch.tags;
	if (temp) {
		filter.watch.tags = null;
	} else {
		filter.watch.tags = "Hot";
	}
	loadData(xType?.value, { ...filter.wParams, page: 1 });
}

function onActive(name) {
	store.xType = name;
	loadData(name, { ...filter.wParams, page: 1 });
}

function onInvestorShow($root, it) {
	state.item = it;
	$root.show("modal-socials");
}

const MAXITEM = 2;

function toItemSlice(ls) {
	if (Array.isArray(ls)) {
		if (ls.length === 3) return ls;
		return ls.slice(0, MAXITEM);
	}
	return [];
}

function onChangePage(page) {
	store.fetchData(xType?.value || xType, { ...filter.wParams, page });
}

// const projectTypes = computed(() => utils.navList[0].childrens);
const catList = computed(() => [{ key: "", name: "ALL" }, ...(filter?.categories || [])]);

function goDetail(item) {
	// $router.push({ name: "w-project-detail", params: { slug: item.slug } });
	store.goDetail(item);
}

function onFilter(key) {
	if (filter.watch.category === key) {
		filter.watch.category = "";
	} else {
		filter.watch.category = key;
	}
	store.onFilter();
}

function loadData(key, params) {
	initCols(key);
	store.fetchData(key, params || {});
}

onMounted(() => {
	utiStore.updateTitle(route?.title || route.meta?.title);
	loadData(xType?.value || xType, { ...filter.wParams, page: 1 });
	if (filter.categories && filter.categories.length === 0) filter.fetchCategories();
});
</script>

<style lang="scss">
.project-wrap {
	display: flex;
	flex-direction: column;
	&.page-content {
		font-family: var(--font-family);
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - var(--nav-height) - 1.5rem);
		transition: var(--trans-out);
		width: 100%;
		@media only screen and (max-width: 768px) {
			height: calc(100vh - var(--nav-mheight) - 0.5rem);
			transition: var(--trans-out);
		}
		&::-webkit-scrollbar {
			-webkit-appearance: none;
			background-color: #5e274e;
			width: 0.25rem;
			border-radius: 0.125rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--color-white);
			border-radius: 0.125rem;
		}
	}
	.tb-toolbar {
		// transition: var(--trans-out);
		z-index: 888;
		display: block;
		width: calc(100vw - var(--nav-sliderbar));
		overflow-x: auto;
		margin: 1rem 0;
		min-height: 4rem;
		&.collapse {
			width: calc(100vw - var(--nav-collapse) - 1rem);
		}
		.toolbar-wrap {
			z-index: 9999;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			overflow-x: auto;
			overflow-y: hidden;
			gap: 1rem;
			padding-bottom: 1rem;
			&::-webkit-scrollbar {
				-webkit-appearance: none;
				background-color: #5e274e;
				height: 0.25rem !important;
				border-radius: 0.25rem;
				transition: height 10s linear;
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--color-white);
				border-radius: 0.25rem;
				cursor: pointer;
				transition: var(--trans-out);
			}

			&:hover {
				&::-webkit-scrollbar {
					height: 0.625rem !important;
					-moz-transition: height 20s ease;
					-webkit-transition: height 20s ease;
					-o-transition: height 20s ease;
					transition: height 20s linear;
					transform: translateY(0);
					transform-origin: top;
				}
			}
			.item-hot {
				padding: 0.75rem 0rem;
				cursor: pointer;
				.hot-deal {
					display: block;
					border-radius: 0.5rem;
					background: linear-gradient(101.61deg, #ff1f6f -14.72%, #870ab1 66.97%);
					color: #fff;
					font-weight: 700;
					padding: 0.5rem 1rem;
					position: relative;
					white-space: nowrap !important;
					text-overflow: ellipsis;
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 1.2rem;
					font-family: var(--font-family);
					transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
					.ic-hot {
						display: none;
						transition: display 0.4s ease-in-out;
					}
					&.active {
						.ic-hot {
							position: absolute;
							top: -0.5rem;
							right: -1.35rem;
							z-index: 99999;
							display: block;
							transition: display 0.4s ease-in-out;
						}
					}
				}
			}
			.nav-item {
				transition: var(--trans-out);
				text-decoration: none;
				padding: 0.25rem 1rem;
				color: rgba(255, 255, 255, 0.5);
				border-radius: 0.5rem;
				background: #66415e;
				font-size: 0.875rem !important;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				width: -moz-fit-content;
				width: fit-content;
				white-space: nowrap;
				padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
				cursor: pointer;
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 1.2rem;
				font-family: var(--font-family);
				transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
				&.active {
					color: var(--color-black);
					background: var(--color-white);
					transition: var(--trans-out);
					font-weight: 600;
				}
			}
		}
	}
	.tb-wrap {
		flex: 1;
		border: 2px solid var(--border-color);
		border-radius: 1.5rem;
		transition: var(--trans-out);
		width: 100%;
		.tb-project {
			min-height: calc(100vh - var(--nav-height) - 12.75rem);
			transition: var(--trans-out);
			&::-webkit-scrollbar {
				-webkit-appearance: none;
				background-color: #5e274e;
				height: 0rem;
				border-radius: 0.125rem;
			}

			&::-webkit-scrollbar-thumb {
				background-color: var(--color-white);
				border-radius: 0.125rem;
			}
			.m-name {
				display: flex;
				flex-direction: row;
				justify-content: start;
				align-items: center;
				gap: 0.5rem;
				text-decoration: none;
				.pro-icon {
					margin-right: 4px;
					width: auto;
					height: 2rem;
					min-width: 26px;
					object-fit: fill;
				}
				.pro-title {
					font-size: 0.875rem !important;
					font-weight: 700;
					line-height: 0.875rem !important;
					font-family: var(--font-family);
				}
				.symbol {
					font-weight: 400;
					font-size: 0.875rem !important;
					color: #b2a0ae;
					font-family: var(--font-family);
				}
			}

			.inv-icon {
				margin-right: 4px;
				width: auto;
				height: 26px;
				object-fit: fill;
			}
			.ic-badge {
				display: flex;
				justify-items: center;
				align-items: center;
				justify-content: center;
				width: 1.5rem;
				height: 1rem;
				border-radius: 0.5rem;
				background-color: #d9d9d9;
				color: #0095ff;
				font-family: var(--font-family);
				font-size: 0.5rem;
				margin-left: 0.5rem;
			}

			.investor-list {
				.b-avatar-group-inner {
					.logo {
						background-color: #641a4a;
						&:hover {
							border-color: #cdcdcd;
							z-index: 100000;
							box-shadow: 0 20px 20px -10px #cdcdcd;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: 768px) {
		.tb-toolbar {
			display: block;
			width: calc(100vw - 2rem);
			transition: var(--trans-out);
			min-height: 3.525rem;
		}
		.tb-wrap {
			font-family: var(--font-family);
			width: calc(100vw - 2.25rem);
			transition: var(--trans-out);
		}
	}
}
</style>
