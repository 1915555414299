const stringIsAValidUrl = (s) => {
	try {
		new URL(s);
		return true;
	} catch (err) {
		return false;
	}
};

export default {
	socialsList: [
		{
			value: "facebook",
			text: "Facebook",
			color: "#1877f2",
			icon: "facebook",
			img: require("../assets/socials/fb.svg"),
		},
		{
			value: "discord",
			text: "Discord",
			color: "#59267c",
			icon: "discord",
			img: require("../assets/socials/discord.svg"),
		},
		{
			value: "telegram",
			text: "Telegram",
			color: "#0088cc",
			icon: "telegram",
			img: require("../assets/socials/telegram.svg"),
		},
		{
			value: "twitter",
			text: "X (Twitter)",
			color: "#1da1f2",
			icon: "twitter",
			img: require("../assets/socials/x.svg"),
		},
		// { value: "viber", text: "Viber", color: "#59267c", icon: "" },
		{
			value: "reddit",
			text: "Reddit",
			color: "#ff4500",
			icon: "reddit",
			img: require("../assets/socials/reddit.svg"),
		},
		{ value: "skype", text: "Skype", color: "#00aff0", icon: "skype", img: require("../assets/socials/skype.svg") },
		// { value: "youtube", text: "Youtube", color: "#bd081c", icon: "" },
		{
			value: "youtube",
			text: "Youtube",
			color: "#bd081c",
			icon: "play-btn",
			img: require("../assets/socials/youtube.svg"),
		},
		{ value: "medium", text: "Medium", color: "#333333", icon: "", img: require("../assets/socials/medium.svg") },
		{ value: "line", text: "Line", color: "#00c300", icon: "", img: require("../assets/socials/line.svg") },
		{
			value: "linkedin",
			text: "LinkedIn",
			color: "#007bb5",
			icon: "linkedin",
			img: require("../assets/socials/linkedin.svg"),
		},
		{
			value: "github",
			text: "Github",
			color: "#007bb5",
			icon: "github",
			img: require("../assets/socials/github.svg"),
		},
		{
			value: "whatsapp",
			text: "Whatsapp",
			color: "#25d366",
			icon: "",
			img: require("../assets/socials/whatsapp.svg"),
		},
		{ value: "baidu", text: "Baidu", color: "#2529d8", icon: "", img: require("../assets/socials/baidu.svg") },
		{ value: "buffer", text: "Buffer", color: "#323b43", icon: "" },
		{ value: "email", text: "Email", color: "#333333", icon: "mailbox" },
		{ value: "evernote", text: "Evernote", color: "#2dbe60", icon: "" },
		{ value: "hackernews", text: "HackerNews", color: "#ff4000", icon: "" },
		{ value: "instapaper", text: "Instapaper", color: "#428bca", icon: "" },
		{ value: "odnoklassniki", text: "Odnoklassniki", color: "#ed812b", icon: "" },
		{ value: "pinterest", text: "Pinterest", color: "#bd081c", icon: "" },
		{ value: "pocket", text: "Pocket", color: "#ef4056", icon: "" },
		{ value: "quora", text: "Quora", color: "#a82400", icon: "" },
		{ value: "sms", text: "SMS", color: "#333333", icon: "chat-text" },
		{ value: "stumbleupon", text: "StumbleUpon", color: "#eb4924", icon: "" },
		{
			value: "messenger",
			text: "Messenger",
			color: "#0084ff",
			icon: "messenger",
			img: require("../assets/socials/messenger.svg"),
		},
		{ value: "tumblr", text: "Tumblr", color: "#35465c", icon: "" },
		{ value: "vk", text: "Vk", color: "#4a76a8", icon: "" },
		{ value: "weibo", text: "Weibo", color: "#e9152d", icon: "", img: require("../assets/socials/weibo.svg") },
		{
			value: "wordpress",
			text: "Wordpress",
			color: "#21759b",
			icon: "",
			img: require("../assets/socials/wordpress.svg"),
		},
		{ value: "xing", text: "Xing", color: "#026466", icon: "" },
		{ value: "yammer", text: "Yammer", color: "#0072c6", icon: "", img: require("../assets/socials/yammer.svg") },
		{ value: "fakeblock", text: "Custom Network", color: "#41b883", icon: "" },
		{ value: "flipboard", text: "Flipboard", color: "#e12828", icon: "" },
	],
	socialColor: {
		facebook: {
			name: "Facebook",
			color: "#1877f2",
		},
		reddit: {
			name: "Reddit",
			color: "#ff4500",
		},
		telegram: {
			name: "Telegram",
			color: "#0088cc",
		},
		twitter: {
			name: "Twitter",
			color: "#1da1f2",
		},
		// viber: {
		// 	name: "Viber",
		// 	color: "#59267c",
		// },
		skype: {
			name: "Skype",
			color: "#1da1f2",
		},
		discord: {
			name: "Discord",
			color: "#59267c",
			link: "viber://forward?text=@t%0D%0A@u%0D%0A@d",
		},
	},
	social: {
		facebook: {
			name: "Facebook",
			color: "#1877f2",
			link: "https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h",
		},
		reddit: {
			name: "Reddit",
			color: "#ff4500",
			link: "https://www.reddit.com/submit?url=@u&title=@t",
		},
		telegram: {
			name: "Telegram",
			color: "#0088cc",
			link: "https://t.me/share/url?url=@u&text=@t%0D%0A@d",
		},
		twitter: {
			name: "Twitter",
			color: "#1da1f2",
			link: "https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu",
		},
		// viber: {
		// 	name: "Viber",
		// 	color: "#59267c",
		// 	link: "viber://forward?text=@t%0D%0A@u%0D%0A@d",
		// },
		// viber: {
		// 	name: "Viber",
		// 	color: "#59267c",
		// 	link: "viber://forward?text=@t%0D%0A@u%0D%0A@d",
		// },
	},
	toShare: function (params) {
		return params.link
			.replace(/@tu/g, "&via=" + encodeURIComponent(params.twitterUser || ""))
			.replace(/@u/g, encodeURIComponent(params.url || ""))
			.replace(/@t/g, encodeURIComponent(params.title || ""))
			.replace(/@d/g, encodeURIComponent(params.description || ""))
			.replace(/@q/g, encodeURIComponent(params.quote || ""))
			.replace(/@h/g, params.encodedHashtags || "")
			.replace(/@m/g, encodeURIComponent(params.media || ""));
	},
	openWindow: function (params) {
		window.open(this.toShare(params), "_blank");
	},
	openLink: function (link, _blank) {
		if (stringIsAValidUrl(link)) window.open(link, _blank || "_blank");
	},
};
