import { defineStore } from "pinia";
import { authApi } from "@/api";
import { localAuth } from "@/utils/local";

export default defineStore("auth", {
	state: () => ({
		isLogin: false,
		show: false,
		isLoading: false,
		user: null,
	}),
	getters: {},
	actions: {
		onToggle(status) {
			this.show = status;
		},
		async onLogout() {
			this.isLogin = false;
			this.user = null;
			localAuth.del();
		},
		async onLogin(form) {
			this.isLoading = true;
			try {
				const data = await authApi.login(form);
				this.saveLocal(data);
				this.show = false;
			} catch (error) {
				this.isLoading = false;
			} finally {
				this.isLoading = false;
			}
		},
		saveLocal({ data, token }) {
			if (token) {
				this.isLogin = true;
				localAuth.save(token);
			}
			if (data) {
				this.user = data;
			}
		},
		onError(err) {
			console.log(err);
		},
		async onGoogleLogin(response) {
			const { access_token, code } = response;
			this.isLoading = true;
			try {
				const data = await authApi.gLogin(access_token || code);
				this.saveLocal(data);
				this.show = false;
			} catch (error) {
				this.isLoading = false;
			} finally {
				this.isLoading = false;
			}
		},
		async autoLogin() {
			if (!this.isLogin) {
				const rs = await authApi.autoLogin();
				if (rs) {
					this.user = rs;
					this.isLogin = true;
				}
			}
		},
	},
});
