<template>
	<b-sidebar
		no-close-on-backdrop
		right
		variant="light"
		backdrop
		shadow
		id="sb-token-sale-add"
		@change="onChange"
		class="text-light"
		@hidden="onHidden"
	>
		<template #header="{ hide }">
			<div class="w-100 d-flex justify-content-between align-items-center align-self-center">
				<div class="text-capitalize">{{ state.edit ? "Edit" : "Add New" }}</div>
				<b-icon-x @click="hide" class="m-action" />
			</div>
		</template>
		<template #default>
			<div class="shadow px-3 py-3">
				<b-row class="text-left">
					<b-col cols="12" md="12">
						<b-form-group label="Stage" label-for="txt-stage">
							<b-form-input
								type="text"
								id="txt-stage"
								placeholder="Stage"
								v-model="form.tStage"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Sale Price" label-for="txt-tprice">
							<b-form-input
								type="text"
								id="txt-tprice"
								placeholder="Sale Price"
								v-model="form.tPrice"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Tokens For Sale" label-for="txt-tSupply">
							<b-form-input
								type="text"
								id="txt-tSupply"
								placeholder="Tokens For Sale"
								v-model="form.tSupply"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Raise" label-for="txt-raise">
							<b-form-input
								type="text"
								id="txt-raise"
								placeholder="Raise"
								v-model="form.tRaise"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label-for="txt-start-at" class="mb-0 ido-date">
							<template #label><span class="t-label">Start At</span></template>
							<b-form-input
								class="ip-date"
								id="txt-start-at"
								:placeholder="FORMAT_DATE"
								v-model="form.tStartAt"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12" class="mt-3">
						<b-form-group label-for="txt-end-at" class="ido-date">
							<template #label><span class="t-label">End At</span></template>
							<b-form-input
								id="txt-end-at"
								:placeholder="FORMAT_DATE"
								v-model="form.tEndAt"
								class="ip-date"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<div class="w-100 b-drive mx-4 my-3"></div>
					<b-col cols="12" md="12">
						<b-form-group label="Launchpad title" label-for="txt-t-title">
							<b-form-input
								type="text"
								id="txt-t-title"
								placeholder="Launchpad title"
								v-model="form.tlpName"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Launchpad link" label-for="txt-link">
							<b-form-input
								type="link"
								id="txt-link"
								placeholder="Launchpad link"
								v-model="form.tlpLink"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Logo" label-for="txt-tlp-logo" class="ido-file">
							<b-form-file
								@change="onLogoUploadLauchpad"
								id="txt-tlp-logo"
								name="file"
								class="text-input"
								placeholder="Drop Logo"
								accept="image/png, image/gif, image/jpeg"
								v-model="form.tlpLogo"
								trim
							></b-form-file>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</template>
		<template #footer="{ hide }">
			<b-row class="text-right my-2 mx-1">
				<b-col>
					<b-btn
						@click="onSave(hide)"
						variant="primary"
						class="btn-ido-primary btn-white w-100"
						:disabled="state.busy"
					>
						<b-icon icon="circle-fill" animation="throb" v-if="state.busy"></b-icon>
						<b-icon-plus v-else /> Add
					</b-btn>
				</b-col>
			</b-row>
		</template>
	</b-sidebar>
</template>

<script setup>
import { mediaApi } from "@/api";
import { useToast } from "@/uses";
import moment from "moment";
import { reactive, defineProps, defineEmits } from "vue";

const toast = useToast();

const props = defineProps(["item", "add"]);

const form = reactive({
	tStage: props.item?.tStage,
	tRaise: props.item?.tRaise,
	tSupply: props.item?.tSupply,
	tlpName: props.item?.tlpName,
	tlpLink: props.item?.tlpLink,
	tStartAt: props.item?.tStartAt,
	tEndAt: props.item?.tEndAt,
	tPrice: props.item?.tPrice,
	tlpLogo: props.item?.tlpLogo,
});
const state = reactive({ busy: false, id: null, formSocials: ["twitter"], logo: null });
const $emit = defineEmits(["onSuccess", "reset"]);

const FORMAT_DATE = "MM/DD/YYYY HH:mm";

async function onLogoUploadLauchpad($event) {
	$event.preventDefault();
	const file = $event.target.files[0];
	if (file) {
		try {
			const { data } = await mediaApi.upload(file, { f: "launchpads" });
			if (data) {
				form.tlpLogo = data;
				toast.success("Upload successfully!");
			}
		} catch ({ message }) {
			toast.warn(message);
		}
	}
}

function onSave(hide) {
	if (!form.tStage) {
		toast.warn("Stage is not empty!");
	} else {
		const result = {};
		for (const key in form) {
			if (Object.hasOwnProperty.call(form, key)) {
				result[key] = form[key];
				if (form[key] === undefined) {
					result[key] = "";
				}
				if (["tStartAt", "tEndAt"].includes(key)) {
					if (form[key] instanceof Date) {
						result[key] = form[key].format(FORMAT_DATE);
					}
				}
			}
		}
		$emit("onSuccess", result, hide, props?.add);
	}
}

function onChange() {
	form.tStage = props.item?.tStage;
	form.tRaise = props.item?.tRaise;
	form.tSupply = props.item?.tSupply;
	form.tlpName = props.item?.tlpName;
	form.tlpLink = props.item?.tlpLink;
	form.tEndAt = props.item?.tEndAt;
	form.tlpLogo = props.item?.tlpLogo;
	if (props.item?.tEndAt) {
		try {
			form.tEndAt = moment(props.item?.tEndAt).format(FORMAT_DATE);
		} catch (error) {
			console.log(error);
		}
	}
	form.tStartAt = props.item?.tStartAt;
	if (props.item?.tStartAt) {
		try {
			form.tStartAt = moment(props.item?.tStartAt).format(FORMAT_DATE);
		} catch (error) {
			console.log("errrr ", error);
		}
	}
	form.tPrice = props.item?.tPrice;
}

function onHidden() {
	$emit("reset");
}
</script>

<style lang="scss">
#sb-token-sale-add {
	font-family: var(--font-family);
	transition: var(--trans-out);
	background-image: var(--bg-linear);
	color: var(--color-white) !important;
	.input-social {
		text-transform: capitalize;
	}

	.b-drive {
		border-top: 1px solid var(--border-color);
	}

	.ido-date {
		.t-label {
			font-size: 0.875rem;
			font-weight: 400;
			color: var(--color-white) !important;
			color: #c384d8;
		}
		.ip-date {
			background-color: transparent;
			border-color: #c384d8;
			color: #c384d8;
			::-webkit-calendar-picker-indicator {
				color: #c384d8;
			}
			::after {
				content: "\1F4C5";
			}
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			color: #c384d8;
			transition: var(--trans-out);

			&::-webkit-calendar-picker-indicator {
				filter: invert(100%);
				transition: var(--trans-out);
			}
		}
		.custom-select {
			background-color: transparent;
			border-color: #c384d8;
			color: #c384d8;
		}
	}
}
</style>
