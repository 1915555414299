<template>
	<b-sidebar
		no-close-on-backdrop
		right
		variant="light"
		backdrop
		shadow
		id="sb-inv-add"
		@change="onChange"
		class="text-light"
		@hidden="onHidden"
	>
		<template #header="{ hide }">
			<div class="w-100 d-flex justify-content-between align-items-center align-self-center">
				<div class="text-capitalize">{{ state.edit ? "Edit" : "Add New" }}</div>
				<b-icon-x @click="hide" class="m-action" />
			</div>
		</template>
		<template #default>
			<div class="shadow px-3 py-3">
				<b-row class="text-left">
					<b-col cols="12" md="12">
						<b-form-group label="Title" label-for="txt-title">
							<b-form-input
								type="text"
								id="txt-title"
								placeholder="Title"
								:readonly="props?.add"
								v-model="form.title"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Website" label-for="txt-website">
							<b-form-input
								type="website"
								id="txt-website"
								placeholder="https://...."
								v-model="form.website"
								class="text-input"
								trim
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Logo" label-for="txt-inv-logo" class="ido-file">
							<b-form-file
								@change="onLogoUpload"
								id="txt-inv-logo"
								name="file"
								class="text-input"
								placeholder="Drop Logo"
								accept="image/png, image/gif, image/jpeg"
								v-model="form.logo"
								trim
							></b-form-file>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="12">
						<b-form-group label="Socials" label-for="txt-socials">
							<template #label>
								<div>
									<span>Socials</span>
									<b-icon-plus-circle
										aria-hidden="true"
										class="ml-3 font-weight-bold m-action"
										v-b-modal.modal-socials
									/>
								</div>
							</template>
							<template v-for="(k, v) in form.socials" :key="v">
								<b-form-group label-for="txt-title">
									<template #label>
										<span class="input-social">{{ v }}</span>
									</template>
									<b-form-input
										:id="`txt-s-${v}`"
										:placeholder="`Link ${v}`"
										type="text"
										class="mb-3 text-input"
										v-model="form.socials[v]"
										trim
									></b-form-input>
								</b-form-group>
							</template>
							<SocialModal @onSuccess="onSuccess" />
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</template>
		<template #footer="{ hide }">
			<b-row class="text-right my-2 mx-1">
				<b-col>
					<b-btn
						@click="onSave(hide)"
						variant="primary"
						class="btn-ido-primary btn-white w-100"
						:disabled="state.busy"
					>
						<b-icon icon="circle-fill" animation="throb" v-if="state.busy"></b-icon>
						<b-icon-plus v-else /> Add
					</b-btn>
				</b-col>
			</b-row>
		</template>
	</b-sidebar>
</template>

<script setup>
import { mediaApi } from "@/api";
import { useToast } from "@/uses";
import { reactive, defineProps, defineEmits } from "vue";
import SocialModal from "./SocialModal";

const toast = useToast();

const props = defineProps(["item", "add"]);

const form = reactive({
	title: props.item?.title,
	logo: props.item?.logo,
	website: props.item?.website,
	socials: props.item?.socials || { twitter: "" },
});
const state = reactive({ busy: false, id: null, formSocials: ["twitter"] });
const $emit = defineEmits(["onSuccess", "reset"]);

function onSuccess(vals) {
	for (let inx = 0; inx < vals.length; inx++) {
		if (form.socials) {
			form.socials[vals[inx]] = form.socials[vals[inx]] || "";
		}
	}
}

async function onLogoUpload($event) {
	$event.preventDefault();
	const file = $event.target.files[0];
	if (file) {
		try {
			const { data } = await mediaApi.upload(file, { f: "investors" });
			if (data) {
				form.logo = data;
				toast.success("Upload successfully!");
			}
		} catch ({ message }) {
			toast.warn(message);
		}
	}
}

function onSave(hide) {
	if (!form.title || !form.logo) {
		toast.warn("Title or Logo is not empty!");
	} else {
		$emit("onSuccess", form, hide, props?.add);
	}
}

function onChange() {
	form.title = props.item?.title || "";
	form.logo = props.item?.logo || "";
	form.website = props.item?.website || "";
	form.socials = props.item?.socials || { twitter: "" };
}

function onHidden() {
	$emit("reset");
}
</script>

<style lang="scss">
#sb-inv-add {
	font-family: var(--font-family);
	transition: var(--trans-out);
	background-image: var(--bg-linear);
	color: var(--color-white) !important;
	.input-social {
		text-transform: capitalize;
	}
}
</style>
