import Vue from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import BootstrapVue, { BootstrapVueIcons } from "bootstrap-vue";
import GoogleLogin from "vue3-google-login";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "vue3-toastify/dist/index.css";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.use(GoogleLogin, {
	clientId: "22930880181-17hfptnpairrcjo9mu2qi785tfcoaq3v.apps.googleusercontent.com",
	clientSecret: "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",
	scope: "profile email openid picture name",
	prompt: "select_account",
	error: (e) => {
		console.log(e);
	},
});
Vue.component("QuillEditor", QuillEditor);
Vue.component("v-select", vSelect);
