import { publicApi } from "@/api";
import { defineStore } from "pinia";
import { useSys } from ".";

const MENU_ACTIVE = "news/menu-active";

export default defineStore("news", {
	state: () => {
		const active = sessionStorage.getItem(MENU_ACTIVE) || "recommend";
		return {
			busy: true,
			items: [],
			total: 0,
			item: null,
			itemBusy: true,
			filter: {
				active,
				s: "",
			},
		};
	},
	getters: {},
	actions: {
		onClickMenu: function (_active) {
			this.filter.active = _active;
			sessionStorage.setItem(MENU_ACTIVE, _active);
		},
		search: function (params) {
			const sys = useSys();
			sys.onUpdateTitle();
			if (!params) params = {};
			if (params?.type) {
				this.filter.active = params.type;
				sessionStorage.setItem(MENU_ACTIVE, params.type);
			}
			this.busy = true;
			if (params.type === "recommend") params.tags = "Recommend";
			publicApi
				.news(params)
				.then(({ data, total }) => {
					this.busy = false;
					this.items = data;
					this.total = total;
				})
				.catch(() => (this.busy = false));
		},
		bySlug: function (slug) {
			this.itemBusy = true;
			publicApi
				.newsSlug(slug)
				.then((data) => {
					this.itemBusy = false;
					this.item = data;
				})
				.catch(() => (this.itemBusy = false));
		},
	},
});
